<template v-if="formActive">

    <div id="job-popup" :class="{'exiting': isExiting, 'is-customer': userCustomer.id != null}">
        <div class="modal fade" id="formModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="jobModalLabel" aria-hidden="true" @keydown.esc="close">
        <div class="modal-dialog" style="max-width: 1400px !important">
        <div class="modal-content">
            <div class="d-flex justify-content-between">
                <h5 class="modal-title" style="width: calc(100% - 820px)" id="jobModalLabel">
                    <span class="d-inline-block p-3">
                        <div v-if="activeTab == 0"> <i class="fas fa-spinner fa-spin"></i> {{$t('form.please_wait')}} </div>
                        <template v-else-if="(!job.id || itemStatus === 'draft') && !isCopy">{{$t('form.add', [$t('navigation.jobs')])}}</template>
                        <template v-else-if="!job.id && isCopy">{{$t('sales.new_x', [$t('naviagtion.jobs')])}}</template>
                        <template v-else-if="job.status == 'pending'">{{$t('operations.booking_confirm')}}</template>
                        <template v-else>{{$t('navigation.jobs') + ' ' + job.number}}</template>
                    </span>
                    <span v-for="editor in editors.slice(0, 3)" :key="editor.id" :title="editor.name" class="badge ms-1" :style="{'background-color': editor.color}">{{editor.initials}}</span>
                    <span v-show="editors.length > 3" class="ms-2">+{{editors.length - 3}}</span>
                    <span v-show="activeTab != 0 && job.id" class="badge ms-2 clickable" @click="changeBookingOpen('add_user')"> <i class="fa fa-user-plus text-dark"></i> </span>
                    <span class="badge">|</span>
                </h5>

                <div class="d-flex">
                    <template v-if="activeTab">
                        <div class="job-nav" v-if="job.quote_id && !userCustomer.id" :data-active="activeTab == 1" @click="setSection(1)">
                            <i class="far fa-chart-line pe-2"></i>{{$t('navigation.sales')}}
                        </div>
                        <div v-else style="width: 100px"></div>
                        <div class="job-nav" :data-active="activeTab == 2" @click="setSection(2)">
                            <i class="far fa-ship pe-2"></i>{{$t('navigation.operations')}}
                        </div>
                        <div class="job-nav" :data-active="activeTab == 3" @click="setSection(3)" v-if="job.status != 'new' && job.status != 'pending' && isEditing">
                            <i class="far fa-book pe-2"></i>{{$t('navigation.documentation')}}
                        </div>
                        <div class="job-nav" :data-active="activeTab == 4" @click="setSection(4)" v-if="job.status != 'new' && job.status != 'pending' && isEditing && !job.user_customer.id">
                            <i class="far fa-sack-dollar pe-2"></i>{{$t('masterdata.finance')}}
                        </div>
                        <template v-if="job.status != 'new' && isEditing">
                            <div class="px-2" style="padding-top: 18px">|</div>
                            <div class="job-nav-alt pe-2" :data-active="activeTab == 5" @click="setSection(5)">
                                <i class="far fa-files pe-2"></i>{{$tc('form.files', 2)}}&ensp;<b class="addon" :class="{'d-none': job.file_info.length == 0}">{{job.file_info.length}}</b>
                            </div>
                            <div class="job-nav-alt" :data-active="activeTab == 6" @click="setSection(6)">
                                <i class="far fa-messages pe-2"></i>{{$t('navigation.activity')}}
                            </div>
                        </template>
                    </template>
                    
                    <div class="px-3" style="padding-top: 12px">
                        <button id="modal-close-button" type="button" class="d-none" data-bs-dismiss="modal" aria-label="Close"></button>
                        <button type="button" id="fakeCloseBtn" class="btn-close" @click="close"></button>
                    </div>
                </div>
            </div>
            <div :data-nofooter="true">
                <ul class="nav nav-main nav-tabs mb-3 px-2" id="jobTab" role="tablist">
                    <template v-if="activeTab == 1">
                        <li class="nav-item px-2" role="presentation">
                            <button class="btn job-nav-button" id="quotations-tab" data-bs-toggle="tab" data-bs-target="#quotations" type="button" role="tab" aria-controls="details">
                                <i class="far fa-chart-line pe-2"></i> {{$t('navigation.quotations')}}
                            </button>
                        </li>
                        <li class="nav-item px-2" role="presentation">
                            <button class="btn job-nav-button" id="addquote-tab" data-bs-toggle="tab" data-bs-target="#addquote" type="button" role="tab" aria-controls="details">
                                <i class="far fa-chart-line pe-2"></i> {{$t('sales.other_costs')}}
                            </button>
                        </li>
                    </template>
                    <template v-else-if="activeTab == 2">
                        <li class="nav-item px-2" role="presentation" v-for="(booking, bindex) in job.bookings" :key="booking.id">
                            <input type="radio" :id="'booking-2-'+bindex" name="selectedbooking" v-model="selectedBooking" :value="bindex" class="d-none"/>
                            <label class="btn job-nav-button" id="bookdetails-tab" :for="'booking-2-'+bindex" data-bs-toggle="tab" data-bs-target="#bookdetails" role="tab" aria-controls="details" @click="resetBLComponents(bindex)">
                                {{booking.number}}
                            </label>
                        </li>
                        <li class="nav-item px-2" role="presentation" v-if="job.status != 'pending' && !job.is_representative && (job.units.length > 0 || !isEditing) && !alteringBooking">
                            <button class="btn job-nav-button" :class="{'pending': isEditing}" id="bookdetails-tab" data-bs-toggle="tab" data-bs-target="#bookdetails" type="button" role="tab" aria-controls="details" @click="addBooking()">
                                <i class="fa fa-plus pe-2"></i>{{isEditing ? (job.units.length + ' ' + $tc('operations.create_booking', job.units.length)) : ($t('sales.new_x', [$t('navigation.bookings')]))}}
                            </button>
                        </li>
                    </template>
                    <template v-else-if="activeTab == 3">
                        <li class="nav-item px-2" role="presentation" v-for="(booking, bindex) in job.bookings" :key="booking.id">
                            <input type="radio" :id="'booking-3-'+bindex" name="selectedbooking" v-model="selectedBooking" :value="bindex" class="d-none"/>
                            <label class="btn job-nav-button" id="bldetails-tab" :for="'booking-3-'+bindex" data-bs-toggle="tab" data-bs-target="#bldetails" role="tab" aria-controls="details" @click="resetBLComponents(bindex, true)">
                                {{booking.number}}
                            </label>
                        </li>
                    </template>
                    <template v-else-if="activeTab == 4">
                        <template v-for="(booking, bindex) in job.bookings" :key="booking.id">
                            <li class="nav-item px-2" role="presentation">
                                <input type="radio" :id="'booking-4-'+bindex" name="selectedbooking" v-model="selectedBooking" :value="bindex" class="d-none invrx" @change="setFinanceTab(0, $event)" />
                                <label class="btn job-nav-button" id="invoices-tab" :for="'booking-4-'+bindex" data-bs-toggle="tab" data-bs-target="#invoices" role="tab" aria-controls="details" @click="resetBLComponents(bindex, true, true)">
                                    {{booking.invoices[0] ? booking.invoices[0].number : booking.number}}
                                </label>
                            </li>
                            <span class="nav-item px-2" role="presentation" v-for="(invoice, invoiceIndex) in booking.invoices.slice(1)" :key="invoiceIndex">
                                <input type="radio" :id="'booking-4-'+bindex+'-'+(invoice.cuid ? invoice.cuid  : invoice.id)" name="selectedbooking" v-model="selectedBooking" :value="bindex" class="d-none invrx" @change="setFinanceTab(invoiceIndex + 1, $event)" />
                                <label class="btn job-nav-button" id="invoices-tab" :for="'booking-4-'+bindex+'-'+(invoice.cuid ? invoice.cuid  : invoice.id)" data-bs-toggle="tab" data-bs-target="#invoices" role="tab" aria-controls="details" @click="resetBLComponents(bindex, true, true)">
                                    {{invoice.id ? invoice.number : 'New invoice'}}<i class="ms-2 fa fa-files"></i>
                                </label>
                            </span>
                        </template>
                    </template>
                </ul>
                <div class="tab-content px-3 pb-2" id="jobTabContent">
                    <div class="tab-pane" id="quotations" role="tabpanel" aria-labelledby="quotations-tab" v-show="activeTab == 1">
                        <QuotationForm ref="quotationform" :formWrapper="this" :fromJob="true" />
                        <div class="d-flex justify-content-end modal-footer fixed-footer">
                            <div id="saveeditjobbutton"></div>
                            <button id="nextbutton" type="button" class="btn btn-success" @click.prevent="qnext">&gt;&ensp;{{$t('form.continue_to_step')}}<span id="nextStep">2</span></button>
                            <div id="hide-surcharge-slider" class="m-0" style="display: none">
                                <FormItem type="slider" :label="$t('sales.show_surcharges')" sizeLabel="8" sizeItem="3" :options="[1, 0]" v-model="hide_surcharges" @onchange="setHideSurcharge" :disabled="hide_surcharges_disabled" />
                            </div>
                            <div id="downloadshowpdfbutton" class="m-0" style="display: none">
                                <FormItem type="download" :label="$t('accounting.show_pdf')" style="margin-right: 25px" startTag="fal fa-file-pdf" :service="downloadPDF" />
                            </div>
                            <button v-if="activeTab == 1" id="savebutton" type="button" class="btn btn-success" @click.prevent="save"><i class="fa fa-spinner fa-spin me-1"></i>{{$t('form.save')}}</button>
                        </div>
                    </div>
                    <div class="tab-pane" id="addquote" role="tabpanel" aria-labelledby="addquote-tab" v-show="activeTab == 1">
                        <JobAdditionalQuotationsForm ref="addquote" :jobItem="job" :currencies="currencies" :suppliers="suppliers" :quoteDescriptions="quoteDescriptions" :isEditing="isEditing" />
                    </div>
                    <div class="tab-pane" id="bookdetails" role="tabpanel" aria-labelledby="bookdetails-tab" @dragover.prevent="" @drop.prevent="" v-show="activeTab == 2">
                        <JobUnitDetailsForm ref="unitdetails" :jobItem="job" :colours="colours" :hscodes="hscodes" :isEditing="isEditing" :loaded="loaded" v-if="isEditing && !creatingNewBooking && !alteringBooking"
                                           :booking="job.bookings && job.bookings[selectedBooking] ? job.bookings[selectedBooking] : false" :index="selectedBooking" :commodities="commodities" />
                        
                        <div v-show="!isEditing || creatingNewBooking || alteringBooking">
                            <JobCreateBookingForm ref="bookingCreate" :jobItem="job" :colours="colours" :commodities="commodities" :hscodes="hscodes" :ports="ports" :customers="customers" 
                                                 :commoditiesById="commoditiesById" :isCreatingNew="isCreatingNew" :userCustomer="userCustomer" />
                        </div>
                    </div>
                    <div class="tab-pane" id="bldetails" role="tabpanel" aria-labelledby="bldetails-tab" v-show="activeTab == 3">
                        <h4>{{$t('operations.booking') + ' ' + (job.bookings && job.bookings[selectedBooking] ? job.bookings[selectedBooking].number : '')}}</h4>
                        <div class="row" v-if="isEditing && job.bookings[selectedBooking].bills_of_lading">
                            <div class="col-sm-auto pe-0 mt-2 position-relative" style="width: 14%">
                                <ul class="nav nav-tabs mb-3 flex-column align-items-end" id="myTab2" role="tablist" @click="setUnitsModalOpen = false">
                                    <li class="nav-item my-1 w-100" role="presentation" v-for="billOflading, index in job.bookings[selectedBooking].bills_of_lading" :key="index">
                                        <button :class="'nav-link-alt ' + (index == 0 ? 'active' : '')" :id="'a'+index+'-tab'" data-bs-toggle="tab" :data-bs-target="'#b'+index" type="button" 
                                                role="tab" :aria-controls="'b'+index" aria-selected="true" @click="setInnerBL(index)">{{billOflading.number.replaceAll('.', '')}}
                                                <i v-if="index != 0" class="fa fa-close text-light px-1" @mousedown="deleteBL($event, job.bookings[selectedBooking], index, billOflading)"></i>
                                                <i v-else class="ps-3 pe-2"></i>
                                        </button>
                                    </li>
                                    <li class="nav-item pt-3 w-100" role="presentation">
                                        <button class="nav-link-alt pe-3 text-white bg-success" type="button" @click="addNewBL(job.bookings[selectedBooking], [], true)">
                                            <span class="fa fa-plus"></span> &nbsp;{{$t('operations.create_bl')}}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-content col-sm-auto border" id="myTabContent2" style="width: 84%">
                                <div v-for="billOflading, index in job.bookings[selectedBooking].bills_of_lading" :key="index" :class="'tab-pane' + (index == 0 ? ' show active' : '')" :id="'b'+index" role="tabpanel" :aria-labelledby="'a'+index+'-tab'">
                                    <div v-if="index != selectedBL"></div>
                                    <JobBillOfLadingComponent v-else :jobItem="job" :colours="colours" :hscodes="hscodes" :currencies="currencies" :countries="countries" :index="index" :isManualResetting="isResetting" :surveyers="surveyers" mode="job" :ports="ports" :active="true"
                                        :containerTypes="containerTypes" :selectedBooking="selectedBooking" :blItem="billOflading" :loaded="loaded" :loadingCountry="job.port_of_loading.country" :dischargeCountry="job.port_of_discharge.country" :isCustomer="job.user_customer.id" />
                                </div>
                            </div>
                        </div>
                        <div class="justify-content-end modal-footer fixed-footer" style="height: 3.5rem"></div>
                    </div>
                    <div class="tab-pane" id="invoices" role="tabpanel" aria-labelledby="invoices-tab" v-show="setInvoice && activeTab == 4">
                        <div v-show="activeInvoice == -1">
                            <JobProFormaInvoiceForm ref="proforma" :jobItem="job" :suppliers="suppliers" :quoteDescriptions="quoteDescriptions" :currencies="currencies" :currenciesById="currenciesById" :isEditing="isEditing" />
                            <div class="d-flex justify-content-end modal-footer fixed-footer" style="height: 55px"></div>
                        </div>
                        <div v-show="activeInvoice != -1">
                            <InvoiceForm ref="invoiceref" :jobItem="job" :itemData="{}" />
                            <div class="d-flex justify-content-end modal-footer fixed-footer">
                                <FormItem type="download" id="xfinishbutton" :label="$t('form.finalize')" :service="finishWithoutEmail" :disabled="true" startTag="fa fa-check-to-slot" />
                                <button id="xemailbutton" type="button" class="btn btn-primary" :disabled="true" @click.prevent="finishEmail">
                                    <i class="fa fa-envelope"></i>&ensp;{{$t('accounting.email_invoice')}}
                                </button>
                                <FormItem type="download" id="pdfbutton" :label="$t('accounting.show_pdf')" :service="showInvoicePDF" />
                                <FormItem type="download" id="invoicesavebutton" :options="{send: true}" startTag="fa fa-save" :label="$t('form.save')" :service="saveInvoicePDF" />
                            </div>
                        </div>
                    </div>
                    <div v-show="activeTab == 5">
                        <Files :files="job.file_info.concat(job.quote.file_info)" @addFile="addFiles" parentItemName="job" ref="files" v-if="isEditing" />
                    </div>
                    <div v-show="activeTab == 6">
                        <ContactHistory :id="job.id" ref="contactHistory" parentItemName="job" comments="1" v-if="isEditing" />
                    </div>
                    <div class="justify-content-end modal-footer fixed-footer" :class="showFooterButons">
                        <div id="newsavebuttons">
                            <div id="confirmorrequest" class="d-none">
                                <div class="d-flex gap-2">
                                    <FormItem type="download" id="bookingrequestbutton" :label="$t('operations.booking_request_action')" startTag="fa fa-messages-question" :service="requestBooking" v-if="!userCustomer.id" />
                                    <FormItem type="download" id="bookingconfirmbutton" :label="$t('operations.booking_confirm')" startTag="fa fa-clipboard-check" :service="saveBooking" :options="{send: true}" />
                                </div>
                            </div>
                            <div id="pendingbookingconfirmbutton" class="d-none" style="display: flex">
                                <FormItem type="download" id="pendingrdownloadbutton" :label="$t('operations.booking_request')" startTag="fa fa-file-pdf" :service="downloadBookingRequest" class="me-2" />
                                <FormItem type="download" id="pendingdownloadbutton" :label="$t('operations.booking_confirm')" startTag="fa fa-clipboard-check" :service="saveBooking" :options="{send: true}" />
                            </div>
                            <div id="alterbookingsavebutton" v-show="alteringBooking">
                                <FormItem type="download" id="alterbookingbutton" :label="$t('form.save')" startTag="fa fa-save" :service="updateBooking" :options="{send: true}" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <JobBookingActionPopupVue ref="bookingActionPopup" />
            <ChangeVoyagePopup :changeVoyageIsOpen="changeVoyageIsOpen" ref="changeVoyagePopup" />
        </div>
        </div>
        </div>
    </div>

</template>

<script>
    import Files from '@/components/formpopup/Files.vue';
    import ContactHistory from '@/components/formpopup/ContactHistory.vue';
    import QuotationForm from '@/components/quotation/QuotationForm.vue';
    import JobCreateBookingForm from '@/components/jobs/JobCreateBookingForm.vue';
    import JobAdditionalQuotationsForm from '@/components/jobs/JobAdditionalQuotationsForm.vue';
    import JobProFormaInvoiceForm from '@/components/jobs/JobProFormaInvoiceForm.vue';
    import JobUnitDetailsForm from '@/components/jobs/JobUnitDetailsFormNew.vue';
    import JobBillOfLadingComponent from '@/components/jobs/BillOfLadingComponentNew.vue';
    import ChangeVoyagePopup from '@/components/voyage/VoyagePlanningChangeVoyagePopup.vue';
    import JobBookingActionPopupVue from '@/components/jobs/JobBookingActionPopup.vue';
    import InvoiceForm from '@/components/invoice/InvoiceFormNew.vue';
    import relationService from "@/services/RelationService";
    import portService from "@/services/PortService";
    import surchargeService from "@/services/SurchargeService";
    import countryservice from "@/services/CountryService";
    import fileService from "@/services/FileService";
    import quoteService from "@/services/QuotationService";
    import billofladingService from '@/services/BillOfLadingService';
    import dataService from '@/services/DataService';
    import dataStore from '@/store/data'
    import store from '@/store/user'
    import jobService from '@/services/JobService';
    import InvoiceService from '@/services/InvoiceService';
   
    export default {
        name: 'JobForm',
        props: ['itemData', 'fromExport'],
        components: {
            Files,
            ContactHistory,
            QuotationForm,
            JobCreateBookingForm,
            JobBillOfLadingComponent,
            JobAdditionalQuotationsForm,
            JobProFormaInvoiceForm,
            JobUnitDetailsForm,
            InvoiceForm,
            ChangeVoyagePopup,
            JobBookingActionPopupVue
        },
        data() {
            return {
                activeTab: 0,
                job: { units: [], additional_quotes: [] },
                blCount: 0,
                userCustomer: {id: null},
                resetMPStatus: false,
                customers: [],
                carriers:[],
                carrier_ids:[],
                commodities: [],
                currencies:[],
                currenciesById:{},
                ports: [],
                voyages: [],
                countries: [],
                surveyers: [],
                hscodes: [],
                colours: [],
                suppliers: [],
                containerTypes: [],
                quoteDescriptions: [],
                loading: false,
                invalid: {},
                isEditing: false,
                isCreatingNew: false,
                alteringBooking: false,
                creatingNewBooking: false,
                selectedBooking: 0,
                selectedBL: 0,
                surcharges: [],
                commoditiesById: {},
                bookingToOpen: null,
                BLToOpen: null,
                detailToOpen: null,
                setUnitsModalOpen: false,
                changeVoyageIsOpen: false,
                setInvoice: false,
                activeInvoice: -1,
                activeInvoiceIndex: -1,
                unitCount: 0,
                quantityOptions: [],
                conditionOptions: [],
                editors: [],
                relationService: relationService,
                resetCheckboxes: false,
                loaded: false,
                isExiting: false,
                isResetting: false,
                dataItemsLoaded: 0,
                dataItemsToLoad: 4,
                formActive: false
            }
        },
        watch: {
            itemData: function (val) { this.job = val }
        },
        computed: {
            showFooterButons() { return ((this.isEditing || this.activeTab == 0) && !this.creatingNewBooking && !this.alteringBooking) ? "d-none" : "d-flex" },
        },
        methods: {
            active(value){
                this.formActive = value;
                this.resetCheckboxes = true;
            },
            validate(){
                return true;
            },
            getData() {
                let returndata = {...this.job}
                returndata.quote_general_costs = returndata.quote_general_costs.filter(row => row.checked)
                return returndata;
            },
            addUser(editor){
                let initials = editor.split(' ').map(n => n[0]);
                let color = editor.toHSL();
                this.editors.push({initials: initials.at(0) + initials.at(-1), color: color, name: editor});
            },
            setData(data, setLastTab = false, resetUnitDescriptions = false) {
                this.loaded = false;
                if(data) {
                    console.log(data);
                    this.isCreatingNew = false;
                    this.job = data;
                    let units = data.units.filter(ql => ql.job_booking_id === null);
                    this.job.units = units;
                    this.$refs.bookingCreate.reset();
                    this.unitCount = 0;
                    for(let unit of this.job.units){
                        unit.checked = 1;
                        unit.measured = unit.booked_measurement && unit.booked_measurement.length && unit.booked_measurement.width && unit.booked_measurement.height && unit.booked_measurement.weight;
                        this.$refs.bookingCreate.setSelectedUnits(unit);
                    }

                    if(!data.quote){
                        this.job.quote = {
                            file_info: [],
                            quote_transport_lines: [],
                            quote_general_costs: [],
                            sales_manager: {office: {id: null}, name: null},
                            quote_lines: []
                        };
                    }
                    if(!data.carriers || data.carriers.length == 0){
                        this.job.carriers = [];
                        this.job.bookings.forEach((booking) => {
                            booking.voyages.forEach((voyage) => {
                                this.job.carriers.push({
                                    id: voyage.carrier.id,
                                    pivot: {carrier_id: voyage.carrier.id},
                                    relation: voyage.carrier.relation
                                });
                            });
                        });
                    }

                    this.isEditing = data.bookings.length > 0 && this.job.status != 'pending';
                    if(this.isEditing){
                        const countries = dataStore.getters.getCountryNames;
                        this.aqCount = 0;
                        this.blCount = 0;
                        this.selectedBooking = window.activeBookingTab ? window.activeBookingTab : 0;
                        this.selectedBL = 0;
                        delete window.activeBookingTab;
                        const isHQ = store.getters.getCompanyHq ? true : false;
                        const isAdmin = store.getters.isAdmin ? true : false;
                        let calcDateDiff = function(date1, date2){ return Math.max(0, Math.floor((new Date(date1) - new Date(date2)) / (1000 * 60 * 60 * 24))); };
                        let today = new Date().toISOString().split('T')[0];

                        this.job.bookings.forEach((booking) => {
                            let billsOfLadingIds = [];
                            booking.operatedByOptions = [];
                            booking.measurements_fixed = !isAdmin;
                            const companyId = store.getters.getCompanyId;
                            if(booking.voyages[0]){
                                if(booking.voyages[0].operators.find(o => o.id == companyId) || (booking.voyages[0].voyage_allocations.length > 0))
                                    booking.measurements_fixed = false;
                                booking.operatedByOptions = booking.voyages[0] ? booking.voyages[0].operators.map(op => ({id: op.id, name: op.relation.name})) : [];
                                booking.operatedByOptions.sort((a, b) => a.name > b.name ? 1 : -1);
                                booking.bookerIsOperator = booking.voyages[0].operators.find(o => o.id == (booking.user ? booking.user.office_id : null)) ? 1 : 0;
                                booking.is_operator = booking.voyages[0].operators.find(o => o.id == companyId) ? 1 : 0;
                            }
                            booking.voyage_info = [];
                            booking.job_booking_voyage.forEach((jbv, jbvindex) => {
                                const bvoyage = booking.voyages.find(v => v.id == jbv.voyage_id);
                                const bcarrier = this.job.carriers.find(c => c.id == bvoyage.carrier.id);
                                if(jbvindex == 0){
                                    booking.voyage_info.push({
                                        name: bvoyage.vessel.name,
                                        port: jbv.port_of_loading.name,
                                        date: jbv.port_of_loading_date
                                    });
                                }
                                const datediff = calcDateDiff(jbvindex == 0 ? jbv.port_of_discharge_date : jbv.port_of_loading_date, today);
                                const totalspan = calcDateDiff(jbvindex == 0 ? jbv.port_of_discharge_date : jbv.port_of_loading_date, booking.voyage_info.at(-1).date);
                                booking.voyage_info.push({
                                    carrier_logo: bcarrier && bcarrier.logo ? bcarrier.logo : null,
                                    name: bvoyage.name,
                                    completion: Math.min(Math.max(Math.floor((datediff / totalspan) * 100), 0), 100),
                                    ddiff: datediff,
                                    tspan: totalspan,
                                    index: jbvindex
                                });
                                booking.voyage_info.push({
                                    name: bvoyage.vessel.name,
                                    port: jbv.port_of_discharge.name,
                                    date: jbv.port_of_discharge_date
                                });
                            });
                            booking.bills_of_lading.forEach((bl) => {
                                billsOfLadingIds.push(bl.id);
                                bl.amount_collect_currency_id = bl.amount_collect_currency_id ? bl.amount_collect_currency_id : window.defaultCurrency;
                                bl.has_second_notify = bl.second_notify !== null ? 1 : 0;
                                bl.showPaymentOptions = false;
                                const voyage = booking.voyages[0];
                                const portStatus = voyage && voyage.voyage_port_statuses[0] ? voyage.voyage_port_statuses[0] : {status: voyage.voyage_status_id != 3 ? 'open' : 'closed'};
                                bl.can_finalize = isHQ || store.getters.canFinalizeOwnBls || bl.operated_by_office_id == store.getters.getCompanyId;
                                bl.uneditable = portStatus.status != 'open' && !bl.can_finalize;
                                bl.voyageClosed = portStatus.status != 'open';
                                if(!bl.job_booking_voyage_manifest){
                                    bl.showPaymentOptions = true;
                                }
                                else{
                                    bl.job_booking_voyage_manifest.forEach(manifest => {
                                        if(manifest.is_nmt_bl) bl.showPaymentOptions = true;
                                    });
                                }
                                if(resetUnitDescriptions){
                                    bl.job_units.forEach(unit => {
                                        unit.description = this.$refs.unitdetails.setUnitDescription(unit);
                                    });
                                }
                                if(!bl.shipper.address && data.customer && data.customer.shipper_address){
                                    bl.shipper = {
                                        "name": "",
                                        "email": data.customer.shipper_address.email,
                                        "phone": data.customer.shipper_address.phone,
                                        "address": data.customer.shipper_address.information + "\n" + data.customer.shipper_address.street,
                                        "country_id": data.customer.shipper_address.country_id
                                    };
                                }
                            });
                            let unitsWithFixedMeasurements = [];
                            booking.job_unit.forEach(unit => {
                                unit.file_info = [];
                                unit.job_unit_message = null;
                                unit.remarks_string = "";
                                unit.also_update_booking_unit = true;
                                if(!unit.make) unit.make = {name: ''};
                                if(!unit.model) unit.model = {name: ''};
                                unit.remarks.forEach(remark => unit.remarks_string += (remark.user.name + ":\n" + remark.content + "\n\n"));
                                unit.remarks_string = unit.remarks_string.substring(0, unit.remarks_string.length - 2);
                                unit.delivered_date = unit.delivered_date ? unit.delivered_date.split(' ')[0] : null;
                                this.unitCount += 1;
                                unit.job_booking_voyage_job_units.forEach((jbvju, jbvjuIndex) => {
                                    unit['is_cleared_' + jbvjuIndex] = jbvju.is_cleared;
                                    unit['is_on_hold_' + jbvjuIndex] = jbvju.is_on_hold;
                                    unit['is_priority_' + jbvjuIndex] = jbvju.is_priority;
                                    jbvju.delivered_date = jbvju.delivered_date ? jbvju.delivered_date.split(' ')[0] : null;
                                    unit['is_delivered_' + jbvjuIndex] = jbvju.delivered_date ? 1 : 0;
                                    unit.is_surveyed = jbvju.is_surveyed;
                                    unit.job_booking_voyage_id = jbvju.id;
                                    unit['id_' + jbvju.job_booking_voyage_id] = jbvju.id;
                                    unit['status_' + jbvjuIndex] = jbvju.status_id;
                                    jbvju.loaded_date = jbvju.loaded_date ? jbvju.loaded_date.split(' ')[0] : null;
                                    unit.loaded_date = jbvju.loaded_date;
                                    unit.measured_measurement = jbvju.measured_measurement;
                                    unit.measured_unit_measurement_id = jbvju.measured_unit_measurement_id;
                                    unit.port_reference = jbvju.port_reference;
                                    if(!jbvju.measured_measurement)
                                        jbvju.measured_measurement = { id: null, width: null, length: null, height: null, weight: null };
                                    if(jbvju.is_checked && jbvju.are_measurements_chosen){
                                        unitsWithFixedMeasurements.push(unit.id);
                                    }

                                    if(!booking.bookerIsOperator && !jbvju.are_measurements_chosen){
                                        jbvju.unit_measurement_type_id = null;
                                        jbvju.unit_weight_type_id = null;
                                    }

                                    for(let bl of booking.bills_of_lading){
                                        let blJobUnit = bl.job_units.find(ju => ju.id == unit.id);
                                        if(blJobUnit){
                                            blJobUnit.is_surveyed = jbvju.is_surveyed;
                                            break;
                                        }
                                    }
                                });
                            });
                            let bookingUnits = booking.job_unit.filter(unit => !billsOfLadingIds.includes(unit.bill_of_lading_id));
                            if(booking.bills_of_lading[0])
                                bookingUnits.forEach(unit => booking.bills_of_lading[0].job_units.push(unit));
                            this.addBLs(booking);
                            let bookingVoyages = this.sendBookingVoyageInfo(booking);
                            booking.bills_of_lading.forEach((bl) => {
                                bl.job_units.forEach(unit => {
                                    if(unitsWithFixedMeasurements.includes(unit.id)){
                                        unit.measurements_fixed = true;
                                    }
                                    const jobUnit = booking.job_unit.find(ju => ju.id == unit.id);
                                    if(!jobUnit.job_booking_voyage_job_units[0] || !jobUnit.job_booking_voyage_job_units[0].measured_measurement) return;
                                    const measurements = jobUnit.job_booking_voyage_job_units[0].unit_measurement_type_id == 2 ? jobUnit.job_booking_voyage_job_units[0].measured_measurement : jobUnit.booked_measurement;
                                    unit.volume = (measurements.width * measurements.height * measurements.length) / 1000000;
                                    unit.weight = measurements.weight;
                                });
                                if(bl.shipper.phone === null) bl.shipper.phone = "";
                                if(bl.notify === null){
                                    bl.notify = {
                                        "name": bl.consignee.name,
                                        "email": bl.consignee.email,
                                        "phone": bl.consignee.phone,
                                        "address": bl.consignee.address,
                                        "country_id": bl.consignee.country_id
                                    }
                                }
                                if(bl.job_booking_voyage_manifest.length == 0){
                                    bl.job_booking_voyage_manifest = this.hardCopy(bookingVoyages);
                                }
                                else{
                                    bl.job_booking_voyage_manifest.forEach((jbvm, jindex) => jbvm.name = (bookingVoyages[jindex] ? bookingVoyages[jindex].name : '???'));
                                    bookingVoyages = this.hardCopy(bl.job_booking_voyage_manifest);
                                }
                                bl.port_of_loading_name = booking.job_booking_voyage[0].port_of_loading.name;
                                bl.port_of_discharge_name = booking.job_booking_voyage.at(-1).port_of_discharge.name;
                                bl.voyage_name = bl.voyage_name ? bl.voyage_name : (booking.voyages[0] ? booking.voyages[0].name.toUpperCase() : '');
                                ['shipper', 'consignee', 'notify'].forEach((type) => {
                                    bl[type].defaultISO = bl[type].country_id ? countries.find(c => c.id == bl[type].country_id).iso : "NL";
                                });
                                if(bl.second_notify && bl.second_notify.country_id) bl.second_notify.defaultISO = countries.find(c => c.id == bl.second_notify.country_id).iso;
                                if(bl.shipper.address && typeof bl.shipper.address == "object"){
                                    bl.shipper.address = bl.shipper.address.street;
                                    bl.consignee.address = bl.consignee.address.street;
                                    bl.notify.address = bl.notify.address.street;
                                }
                            });
                        });
                        this.$nextTick(() => this.$refs.proforma.setQuoteInvoiceLines());
                    }
                    else if(this.job.quote && this.job.quote.quote_routes && this.job.quote.quote_routes[0]){
                        const segments = this.job.quote.quote_routes[0].quote_route_segments;
                        if(segments.length > 1) this.$refs.bookingCreate.preferredVoyageIds.push(segments.map(s => (s.voyage_id+'_'+s.port_of_loading_id+'_'+s.port_of_discharge_id)).join("-") );
                        else this.$refs.bookingCreate.preferredVoyageIds.push(Number(this.job.quote.quote_routes[0].voyage_id));
                    }
                    else if(this.job.voyages){
                        for(let voyage of this.job.voyages)
                            this.$refs.bookingCreate.preferredVoyageIds.push(voyage.id);
                        delete this.job.voyages;
                    }
                    if(this.job.status == 'pending'){
                        this.job.pending_booking = {...this.job.bookings.at(-1)};
                        this.job.pending_booking.carrier_reference = '';
                    }
                    this.bookingToOpen = window.tabToOpen !== undefined ? window.tabToOpen : null;
                    this.BLToOpen = window.BLToOpen !== undefined ? window.BLToOpen : null;
                    this.detailToOpen = location.search ? Number(location.search.substring(3)) : null;
                    this.activeTab = 2;
                    if(setLastTab) this.selectedBooking = this.job.bookings.length - 1;
                    this.setSection(this.activeTab, setLastTab);
                }

                if(this.dataItemsLoaded < this.dataItemsToLoad) return;
                if(this.job.carriers === undefined) return;

                if(this.job.fileInfo === undefined)
                    this.job.fileInfo = [];

                this.$nextTick(() => {
                    if(this.isEditing){
                        document.getElementById("confirmorrequest").classList.add('d-none');
                        this.editors = [];
                        this.job.users.forEach(user => this.addUser(user.user.name));
                        
                        if(this.bookingToOpen != null){
                            this.activeTab = window.financeOpen ? 4 : 3;
                            delete window.financeOpen;
                            this.bookingToOpen = typeof this.bookingToOpen == "string" ? this.job.bookings.findIndex(bk => bk.number == this.bookingToOpen) : this.bookingToOpen;
                            this.selectedBooking = this.bookingToOpen;
                            if(window.createNewJobInvoice){
                                delete window.createNewJobInvoice;
                                window.setLatestInvoice = true;
                                this.job.bookings[this.bookingToOpen].invoices.push({id: null, cuid: 999, number: 'New invoice', amount: null, currency_id: null, due_date: null, status: 'draft', file_info: []});
                                this.activeInvoice = null;
                                this.activeInvoiceIndex = this.job.bookings[this.bookingToOpen].invoices.length - 1;
                                this.setInvoice = true;
                                if(window.storedInvoiceData){
                                    this.setCopyInvoiceData(window.storedInvoiceData);
                                    delete window.storedInvoiceData;
                                }
                                this.$nextTick(() => {
                                    document.getElementById('booking-4-'+this.bookingToOpen+'-999').click();
                                });
                            }
                            else{
                                this.setSection(this.activeTab);
                            }
                            if(this.BLToOpen != null){
                                const blindex = this.job.bookings[this.bookingToOpen].bills_of_lading.findIndex(bl => bl.number == this.BLToOpen);
                                this.$nextTick(() => {
                                    window.setTimeout(() => document.getElementById('a' + blindex + '-tab').click(), 100);
                                });
                            }
                        }
                        else if(this.detailToOpen != null){
                            window.setTimeout(() => {const el = document.getElementById("ub-" + this.detailToOpen); if(el) el.click();}, 100);
                        }

                        this.job.additional_quotes.forEach(quote => {
                            quote.additional_quote_lines.forEach(ql => ql.add_job_units = ql.job_units.length > 0);
                            this.aqCount++;
                        })
                    }
                    else if(this.job.status == 'pending'){
                        document.getElementById("pendingbookingconfirmbutton").classList.remove('d-none');
                        document.getElementById("confirmorrequest").classList.add('d-none');
                        document.getElementById("newsavebuttons").classList.remove('disabled');
                    }
                    else{
                        document.getElementById("confirmorrequest").classList.remove('d-none');
                        this.$refs.bookingCreate.setLicensePlateRequired();
                    }

                    this.$nextTick(() => {
                        //Set message feed
                        if(this.isEditing){
                            if(this.resetCheckboxes){
                                this.$refs.unitdetails.reset();
                                this.resetCheckboxes = false;
                            }
                            if(this.bookingToOpen != null){
                                this.bookingToOpen = null;
                                delete window.tabToOpen;
                            }
                            if(this.detailToOpen != null){
                                window.history.pushState("", "", location.href.replace(/\?q=\d+/, ''));
                            }
                            this.$refs.contactHistory.empty();
                            this.$refs.contactHistory.getMessages(this.job.messages);
                            this.setInvoice = true;
                        }
                        else{
                            window.setTimeout(() => this.$refs.bookingCreate.validateTabOne(), 200);
                        }

                        this.$refs.bookingCreate.setNextButtonDisabled();
                        this.resetBLComponents();
                        this.loaded = true;
                    });
                    let inputArray = [];
                    if(this.job.is_forward){
                        this.job.carriers.forEach((carr) => {
                            if(carr.id === this.job.quote.option_carrier_id){
                                inputArray.push({"port_of_loading_id": this.job.port_of_loading.id, "carrier_id": carr.pivot ? carr.pivot.carrier_id : carr.id});
                            }
                        })
                    }
                    else{
                        if(this.job.carriers.length  == 0){
                            inputArray.push({"port_of_loading_id": this.job.port_of_loading.id, "carrier_id": null});
                        }
                        else{
                            this.job.carriers.forEach((carr) => {
                                inputArray.push({"port_of_loading_id": this.job.port_of_loading.id, "carrier_id": carr.pivot ? carr.pivot.carrier_id : carr.id});
                            });
                        }
                    }
                    
                    this.$refs.bookingCreate.getVoyagesMultiple(inputArray);
                    this.getSurcharges();
                });
            },
            reset(isExistingJob = true) {
                this.isCreatingNew = !isExistingJob;
                this.selectedBooking = window.activeBookingTab ? window.activeBookingTab : 0;
                this.selectedBL = 0;
                this.setUnitsModalOpen = false;
                this.creatingNewBooking = false;
                this.isExiting = false;
                this.activeTab = 0;
                this.editors = [];
                document.querySelector(".modal-body").style.overflowY = "auto";
                document.getElementById("pendingbookingconfirmbutton").classList.add('d-none');
                if(this.isCreatingNew){
                    this.$refs.bookingCreate.reset();
                }
                if(this.dataItemsLoaded < this.dataItemsToLoad){
                    this.bookingToOpen = null;
                    this.job.quote_general_costs = [];
                    this.loadData();
                    return;
                }
                if(this.isCreatingNew){
                    this.isEditing = false;
                    document.getElementById("confirmorrequest").classList.remove('d-none');
                    document.getElementById("newsavebuttons").setAttribute('class', 'disabled');
                    this.job = {
                        additional_quotes: [],
                        bookings: [],
                        customer_id: null,
                        units: [],
                        port_of_loading: {},
                        port_of_discharge: {},
                        port_of_loading_id: null,
                        port_of_discharge_id: null,
                        carriers: this.carriers,
                        receive_stat_mail: false
                    };
                    if(this.userCustomer.id){
                        this.job.customer_id = this.userCustomer.id;
                        this.job.customer = {id: this.userCustomer.id, relation: {name: this.userCustomer.name}};
                    }
                    this.$refs.bookingCreate.loaded = true;
                    this.$nextTick(() => {
                        this.setSection(2);
                        this.$nextTick(() => {
                            this.$refs.bookingCreate.suggestNewJobUnit();
                            document.querySelectorAll("#customer .simple-typeahead-reset").forEach(el => el.click());
                        });
                    });
                }
            },

            setSection(step){
                if(step != 4 && this.activeTab == 4 && this.activeInvoice != -1){
                    this.$refs.invoiceref.saveJobInvoice();
                }
                this.activeTab = step;
                if(step == 1 && !this.$refs.quotationform.formActive){
                    this.$refs.quotationform.active(true);
                    this.$refs.quotationform.reset();
                    quoteService.show(this.job.quote_id).then(response => {
                        this.$refs.quotationform.setData(response.data);
                    }).catch(error => console.log('error', error));
                }
                if(step == 4)
                    this.setFinanceTab();
                if(step < 5)
                    this.$nextTick(() => {
                        const tabnr = step > 1 && step < 5 ? (this.selectedBooking + 1) : 1; 
                        const el = document.querySelector("#jobTab > li:nth-of-type("+tabnr+") > .btn");
                        if(el) el.click();
                        this.$refs.proforma.setTab(this.selectedBooking);
                    });
            },

            loadData() {
                relationService.index(null,null,'customer').then(response => {
                    this.customers = response.data
                    this.$refs.quotationform.customers = response.data;
                    this.checkData();
                }).catch(error => console.log('error', error))
                portService.indexWithRequirements().then(response => {
                    this.ports = response.data
                    this.$refs.quotationform.ports = response.data;
                    this.checkData();
                }).catch(error => console.log('error', error))
                countryservice.indexForBL().then(response => {
                    this.countries = response.data
                    this.checkData();
                }).catch(error => console.log('error', error))
                dataService.getJobData().then(response => {
                    this.colours = response.data.colours;
                    this.hscodes = response.data.hs_codes;
                    this.quoteDescriptions = response.data.quotation_descriptions;
                    this.commodities = response.data.commodities;
                    this.commoditiesById = {};
                    response.data.commodities.forEach(comm => this.commoditiesById[comm.id] = comm);
                    this.suppliers = response.data.suppliers;
                    this.surveyers = response.data.surveyors;
                    this.currencies = response.data.currencies;
                    this.userCustomer = response.data.user_customer;
                    this.currenciesById = {};
                    response.data.currencies.forEach(curr => this.currenciesById[curr.id] = curr );
                    window.defaultCurrency = this.currencies.filter(currency => currency.is_default == 1)[0].id;
                    this.$refs.proforma.defaultCurrency = window.defaultCurrency;
                    this.$refs.proforma.tempCurrency = window.defaultCurrency;
                    this.$refs.quotationform.currencies = response.data.currencies;
                    this.checkData();
                }).catch(error => console.log('error', error));
            },
            changeVoyageOpen(){ this.changeVoyageIsOpen = false },
            changeBookingOpen(mode){ this.$refs.bookingActionPopup.setMode(mode) },
            async updateBooking(){ return this.$refs.bookingCreate.updateBooking() },
            changeBookingCreate(mode){ 
                this.$refs.bookingCreate.setMode(mode);
                this.alteringBooking = true;
            },
            bulkActionUnits(port, name, value, voyageInfo, reasons){
                this.changeVoyageIsOpen = false;
                this.$refs.unitdetails.changeVoyage(value, voyageInfo, reasons);
            },
            
            createBooking(isRequest = false, afterDialog = false){
                if(this.loading) return false;

                if(this.job.pending_booking){
                    this.loading = true;
                    return jobService.setPendingBooking(this.job.id, this.job.pending_booking).then(response => {
                        this.afterBookingCreation(false, response);
                    }).catch(error => console.log('error', error));
                }
                let booking = this.$refs.bookingCreate.getBookingInfo();
                const mode = isRequest ? 'request_action' : 'confirm';
                if(!afterDialog && !window.localStorage.getItem("set_" + mode)){
                    this.$refs.bookingCreate.dialogMode = isRequest ? 'request_action' : 'confirm';
                    document.getElementById("confirm-dialog").showModal();
                    return;
                }
                this.loading = true;
                if(this.isCreatingNew){
                    this.job.voyages = booking.voyages;
                    if(isRequest)
                        this.job.is_request = true;
                    return jobService.store(this.job).then(response => this.afterBookingCreation(isRequest, response)).catch(error => console.log('error', error));
                }
                else{
                    if(isRequest)
                        booking.is_request = true;
                    return jobService.createBooking(this.job.id, booking).then(response => this.afterBookingCreation(isRequest, response, true)).catch(error => console.log('error', error));
                }
            },
            afterBookingCreation(isRequest, response, setLastTab = false){
                this.loading = false;
                this.$parent.getIndex();
                this.resetMPStatus = true;
                const newBooking = response.data.bookings.at(-1);
                const bookingJobUnits = newBooking.job_unit.map(ju => ju.id).join('-');
                if(isRequest){
                    document.getElementById("modal-close-button").click();
                    jobService.downloadPDF('booking-request', bookingJobUnits).then(rx => this.triggerDownload(rx.data, newBooking.number + "_booking_request.pdf") ).catch(error => this.toastError(error));
                }
                else{
                    this.reset();
                    this.setData(response.data, setLastTab);
                    jobService.downloadPDF('combi-zip', bookingJobUnits).then(rx => this.triggerDownload(rx.data, newBooking.number + "_documents.zip") ).catch(error => this.toastError(error));
                }
            },
            proceed(){
                const mode = this.$refs.bookingCreate.dialogMode;
                if(this.$refs.bookingCreate.hasProceedChecked)
                    window.localStorage.setItem("set_" + mode, 1);
                this.createBooking(mode == "request_action", true);
            },

            addBLs(booking){
                if(booking.bills_of_lading.length > 0){
                    booking.bills_of_lading.forEach(bl => {
                        this.blCount++;
                        if(!bl.number)
                            bl.number = booking.number.replace('B', '') + ('000' + this.blCount).slice(-3);
                    });
                    return;
                }

                let jobUnits = booking.job_unit.filter(ju => ju.bill_of_lading_id === null && !ju.is_cancelled);
                this.addNewBL(booking, jobUnits);
            },
            addNewBL(booking, jobUnits = [], showAddNewUnitsPopup = false){
                const newAddress = { "name": "", "email": "", "phone": "", "address": "", "country_id": null };
                const noDefaultShipper = !this.job.customer || this.job.customer.shipper_address === null;
                const lastBL = booking.bills_of_lading[0] ? {...booking.bills_of_lading.at(-1)} : null;
                let highestBLNumber = booking.number.replace('B', '') + '000';
                booking.bills_of_lading.forEach(jbl => {
                    if(jbl.number > highestBLNumber)
                        highestBLNumber = jbl.number;
                });
                const lastNumber = highestBLNumber;
                const blCount = ('000' + (parseInt(lastNumber.substring(lastNumber.length - 3)) + 1)).slice(-3);
                const newBL = lastBL ? this.hardCopy(lastBL) : {
                    "id": null,
                    "number": lastNumber.substring(0, lastNumber.length - 3) + blCount,
                    "job_units": jobUnits,
                    "bl_type": "Original",
                    "payment_option": "PREPAID",
                    "job_booking_id": booking.id,
                    "shipper": {
                        "name": "",
                        "email": (noDefaultShipper || this.job.customer.shipper_address.email === null) ? '' : this.job.customer.shipper_address.email,
                        "phone": (noDefaultShipper || this.job.customer.shipper_address.phone === null) ? '' : this.job.customer.shipper_address.phone,
                        "address": noDefaultShipper ? '' : this.job.customer.shipper_address.information + "\n" + this.job.customer.shipper_address.street,
                        "country_id": noDefaultShipper ? null : this.job.customer.shipper_address.country_id
                    },
                    "consignee": this.hardCopy(newAddress),
                    "notify": this.hardCopy(newAddress),
                    "notify_same_as_consignee": 0,
                    "has_second_notify": 0,
                    "one_third_per_shipsmail": 0,
                    "collect_clause": 0,
                    "reference": "",
                    "amount_collect": null,
                    "internal_notes": null,
                    "additional_notes": null,
                    "amount_collect_currency_id": window.defaultCurrency,
                    "port_of_loading_id": booking.job_booking_voyage[0].port_of_loading.id,
                    "port_of_discharge_id": booking.job_booking_voyage.at(-1).port_of_discharge.id,
                    "port_of_loading_name": booking.job_booking_voyage[0].port_of_loading.name,
                    "port_of_discharge_name": booking.job_booking_voyage.at(-1).port_of_discharge.name,
                    "in_transit": null,
                    "country_id": null,
                    "is_surveyed": null,
                    "surveyor_id": null,
                    "job_unit_containers": [],
                    "job_booking_voyage_manifest": this.sendBookingVoyageInfo(booking),
                    "customer_id": this.job.customer_id,
                    "voyage_id": booking.voyages[0] ? booking.voyages[0].id : null,
                    "port_of_final_destination_id": null,
                    "release_type": 'PRINT',
                    "is_letterofcredit": 0,
                    "lc_marks_and_numbers": "",
                    "lc_description_of_goods": "",
                    "lc_port_of_loading_name": "",
                    "lc_port_of_discharge_name": "",
                    "lc_port_of_final_destination_name": "",
                    "voyage_name": booking.voyages[0] ? booking.voyages[0].name.toUpperCase() : '',
                    "city": "",
                    "manifest_office_id": null,
                    "bl_date": null,
                    "office_name": null,
                    "is_collectionfee": 0,
                    "printdimensions": 0,
                    "dimensiondecimals": 3,
                    "additionalclause_id": null,
                    "shipper_name": "",
                    "consignee_name": "",
                    "notify_name": "",
                    "representative_name": "",
                    "local_vessel": null,
                    "local_vessel_port": null,
                    "port_of_final_destination": null,
                    "update_general_details": true,
                    "update_job_units": true,
                    "preload_vessel_id": null,
                    "preload_port_id": null,
                    "preload_departure_date": null,
                    "bill_of_lading_status_id": 1,
                    "issuer": booking.description == 'optional' ? null : 'carrier',
                    "carrier_reference": booking.description == 'optional' ? '' : booking.description,
                    "can_finalize": true,
                    "freight": "",
                    "operated_by_office_id": booking.voyages[0] && booking.voyages[0].operators[0] ? booking.voyages[0].operators[0].id : null
                };
                if(lastBL){
                    newBL.id = null;
                    newBL.number = lastNumber.substring(0, lastNumber.length - 3) + blCount;
                    newBL.job_units = jobUnits;
                    delete newBL.shipper.id;
                    delete newBL.consignee.id;
                    delete newBL.notify.id;
                    if(newBL.second_notify) delete newBL.second_notify.id;
                    newBL.job_unit_containers = [];
                    newBL.job_booking_voyage_manifest = this.sendBookingVoyageInfo(booking);
                    newBL.can_finalize = true;
                    newBL.bill_of_lading_status_id = 1;
                    newBL.update_general_details = true;
                    newBL.update_job_units = true;
                    newBL.voyage_name = booking.voyages[0] ? booking.voyages[0].name.toUpperCase() : newBL.voyage_name;
                }
                let newBLLength = booking.bills_of_lading.push(newBL);

                if(!jobUnits.length){
                    this.$nextTick(() => document.getElementById('a' + (newBLLength - 1) + '-tab').click());
                }
                if(showAddNewUnitsPopup){
                    this.$nextTick(() => this.$nextTick(() => {
                            const el = document.querySelector('#myTabContent2 > div:last-child .link');
                            if(el) el.click();
                        })
                    );
                }
            },
            setFinanceTab(invIndex = 0, e = null){
                if(e){
                    let els = document.querySelectorAll(".invrx");
                    els.forEach(el => {
                        el.checked = false;
                        el.nextElementSibling.classList.remove('active');
                    });
                    e.target.checked = true;
                    e.target.nextElementSibling.classList.add('active');
                }
                let invoiceIndex = invIndex;
                if(window.setLatestInvoice){
                    invoiceIndex = this.job.bookings[this.selectedBooking].invoices.length - 1;
                }
                this.activeInvoiceIndex = invoiceIndex;
                if(this.job.bookings[this.selectedBooking] && this.job.bookings[this.selectedBooking].invoices && this.job.bookings[this.selectedBooking].invoices[invoiceIndex]){
                    this.activeInvoice = this.job.bookings[this.selectedBooking].invoices[invoiceIndex].id;
                    this.$refs.invoiceref.reset();
                    this.$refs.invoiceref.active(1);
                    if(window.setLatestInvoice){
                        delete window.setLatestInvoice;
                        if(this.job.bookings[this.selectedBooking].invoices[invoiceIndex].invoice_lines){
                            this.$refs.invoiceref.isEditing = true;
                            this.$refs.invoiceref.noEdit = true;
                            this.$refs.invoiceref.setData(this.job.bookings[this.selectedBooking].invoices[invoiceIndex]);
                        }
                        return;
                    }
                    if(this.job.bookings[this.selectedBooking].invoices[invoiceIndex].invoice_lines){
                        this.$refs.invoiceref.isEditing = true;
                        this.$refs.invoiceref.noEdit = true;
                        console.log({...this.job.bookings[this.selectedBooking].invoices[invoiceIndex]})
                        this.$refs.invoiceref.setData(this.job.bookings[this.selectedBooking].invoices[invoiceIndex]);
                        return;
                    }
                    InvoiceService.show(this.activeInvoice).then(response => {
                        this.job.bookings[this.selectedBooking].invoices[invoiceIndex] = response.data;
                        this.$refs.invoiceref.noEdit = false;
                        this.$refs.invoiceref.setData(response.data);
                        this.activeInvoice = response.data.id;
                    }).catch(error => console.log('error', error));
                }
                else{
                    this.activeInvoice = -1;
                    this.$refs.proforma.setTab(this.selectedBooking);
                }
                delete window.setLatestInvoice;
            },
            resetBLComponents(bookingId = null, setBL = false, setInvoice = false){
                if(bookingId){
                    this.setUnitsModalOpen = false;
                    document.getElementById("confirmorrequest").classList.add('d-none');
                }
                if(setBL){
                    if(this.BLToOpen) this.BLToOpen = null;
                    else window.setTimeout(() => document.querySelector("#myTab2 > li > button").click(), 150);
                }
                if(setInvoice){
                    this.setFinanceTab();
                }
                if(this.isEditing)
                    this.isResetting = true;
                this.creatingNewBooking = false, this.alteringBooking = false;
            },
            resetBLData(newItem, blIndex, unitIndex){
                this.job.bookings[this.selectedBooking].bills_of_lading[blIndex].job_units[unitIndex] = newItem;
                const hasLoadedUnits = this.job.bookings[this.selectedBooking].bills_of_lading[blIndex].job_units.every(ju => 
                    ju.job_booking_voyage_job_units && 
                    ju.job_booking_voyage_job_units.every(jbvju => jbvju.status_id == 4)
                ) ? 1 : 0;
                
                if(hasLoadedUnits && this.job.bookings[this.selectedBooking].bills_of_lading[blIndex].bill_of_lading_status_id == 1)
                    this.job.bookings[this.selectedBooking].bills_of_lading[blIndex].bill_of_lading_status_id = 2;
                else if(!hasLoadedUnits && this.job.bookings[this.selectedBooking].bills_of_lading[blIndex].bill_of_lading_status_id == 2)
                    this.job.bookings[this.selectedBooking].bills_of_lading[blIndex].bill_of_lading_status_id = 1;

                if(this.isEditing)
                    this.isResetting = true;
            },
            setInnerBL(index){
                this.selectedBL = index;
                this.$nextTick(() => document.querySelector('#job-popup #fixed-unit-list > div').click());
            },
            deleteBL(e, booking, index, bl){
                if(e && !e.target.parentElement.classList.contains('active')) return;
                if(!e || confirm(this.$t('form.are_you_sure_delete') + ' ' + bl.number + '?')){
                    const afterDeletion = function(booking, bl, parEl){
                        const prevUnits = [...bl.job_units];
                        prevUnits.forEach(pu => delete pu.bill_of_lading_id);
                        booking.bills_of_lading.splice(index, 1);
                        if(booking.bills_of_lading.length == 0)
                            this.addNewBL(booking);
                        prevUnits.forEach(unit => booking.bills_of_lading[0].job_units.push(unit));
                        booking.bills_of_lading.push({});
                        booking.bills_of_lading.pop();
                        parEl.$refs.proforma.setQuoteInvoiceLines();
                        parEl.$nextTick(() => document.querySelectorAll("#bldetails .nav-item > button")[0].click());
                    }
                    if(bl.id)
                        jobService.deleteBL(bl.id).then(() => afterDeletion(booking, bl, this)).catch(error => console.log('error', error));
                    else
                        afterDeletion(booking, bl, this);
                }
            },
            sendBookingVoyageInfo(booking){
                let bookingVoyages = [];
                booking.job_booking_voyage.forEach(bv => {
                    let foundVoyage = booking.voyages.find(b => b.id == bv.voyage_id);
                    let pol = bv.port_of_loading ? bv.port_of_loading.code : this.job.port_of_loading.code;
                    let pod = bv.port_of_discharge ? bv.port_of_discharge.code : this.job.port_of_discharge.code;
                    let name = foundVoyage ? (foundVoyage.vessel.name + ' ' + foundVoyage.number + ' ' + pol + ' - ' + pod) : '???';
                    bookingVoyages.push({
                        job_booking_voyage_id: bv.id,
                        is_nmt_bl: null,
                        is_consolidated: false,
                        name: name
                    });
                });
                return bookingVoyages;
            },

            getSurcharges(){
                let carriers = [];
                if(this.isEditing)
                    this.job.carriers.forEach(carr => carriers.push(carr.carrier_id))
                else
                    carriers = this.carrier_ids;

                let pol = this.job.port_of_loading.id;
                let pod = this.job.port_of_discharge.id;
                surchargeService.search(carriers, [pol], pod, this.job.created_at).then(response => {
                    this.surcharges = response.data
                }).catch(error => console.log('error', error));
            },

            async downloadExcel(args) {
                const type = args[0], value = args[1], name = args[2];
                return jobService.downloadExcel(type, value).then(response => {
                    this.triggerDownload(response.data, name + "_ISF.xlsx");
                }).catch(error => this.toastError(error) );

            },
            async save(args) {
                const bl = args[0], index = args[1], ref = args[2], download = args[3];
                const is_final = bl.bill_of_lading_status_id == 3;
                const blDoc = document.getElementById("b"+index);
                //Validation - Tab 2
                if(!bl.release_type && bl.bill_of_lading_status_id > 2){
                    alert("Please select the release type");
                    return;
                }
                if(!this.userCustomer.id && (!bl.payment_option || !bl.bl_type) && bl.bill_of_lading_status_id > 2){
                    alert(this.$t('errors.bl'));
                    return false;
                }
                /*if(ref.hasSurveyedUnits(bl) && !bl.surveyor_id){
                    alert(this.$t('errors.bl_2'));
                    return false;
                }*/
                if(bl.in_transit && !bl.country_id){
                    alert(this.$t('errors.bl_3'));
                    return false;
                }
                if(bl.shipper.address === null) bl.shipper.address = '';
                const shippername = bl.shipper_name ? bl.shipper_name.toUpperCase() : bl.shipper.address.toUpperCase();
                if(!bl.shipper_name){
                    bl.shipper_name = shippername;
                }
                if(is_final && bl.bl_type == 'Original'){
                    //const consigneename = bl.consignee_name ? bl.consignee_name.toUpperCase() : bl.consignee.address.toUpperCase();
                    //if(shippername == "TO ORDER" || consigneename == "TO ORDER"){
                    //    alert(this.$t('errors.bl_5'));
                    //    return false;
                    //}
                }
                const requiredQuery = is_final ? "#iteniary input:not([type='checkbox'])" : ("#iteniary .col-6.position-relative input:not([type='checkbox']), #iteniary .col-6.position-relative .vue-tel-input");
                let isValid = true;
                let required = blDoc.querySelectorAll(requiredQuery);
                for(let field of required){
                    let value = field.value;
                    if(field.tagName == "DIV"){
                        value = field.children[1].value;
                    }
                    if((field.required || field.classList.contains('required')) && !value){
                        field.classList.add('is-invalid');
                        isValid = false;
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                }
                if(!isValid){
                    alert(this.$t('errors.empty_fields'));
                    return false;
                }
                //Tab 3
                if(bl.job_units.length == 0){
                    alert("This BL has no units. Please add at least one unit.");
                    return false;
                }
                let invalidUnitTab = 0;
                for(let uindex in bl.job_units){
                    const unit = bl.job_units[uindex];
                    delete unit.notSavedWarning;
                    invalidUnitTab = uindex;
                    isValid = unit.chassis_number && unit.weight && unit.volume;
                    if(ref.makeModelRequired && (!unit.unit_make_id || !unit.unit_model_id)) isValid = false;
                    else if(ref.buildYearRequired && !unit.construction_year)                isValid = false;
                    else if(ref.colourRequired && !unit.colour_id)                           isValid = false;
                    else if(ref.hsCodeRequired && !unit.hs_code)                             isValid = false;
                    else if(ref.licensePlateRequired && !unit.license_plate)                 isValid = false;

                    if(!isValid){
                        break;
                    }
                }
                if(!isValid){
                    alert(this.$t('errors.empty_fields'));
                    this.$nextTick(() => {
                        blDoc.querySelector('#uli-'+bl.id+'-'+invalidUnitTab).click();
                        window.setTimeout(() => {
                            const req = blDoc.querySelectorAll('#fixed-unit-details input[required], #fixed-unit-details select[required]');
                            req.forEach(el => {
                                if(!el.value || el.value == this.$t('form.select'))
                                    el.classList.add('is-invalid');
                            })
                        }, 500);
                    });
                    return false;
                }

                bl.update_general_details = true;
                bl.update_job_units = true;
                this.resetMPStatus = true;
                const userName = store.getters.getName;
                if(!this.editors.find(editor => editor.name == userName)) this.addUser(userName);
                if(!bl.id){
                    return billofladingService.store(bl).then((response) => {
                        bl.id = response.data.id;
                        bl.number = response.data.number;
                        if(download) return jobService.downloadPDF('bill-of-lading', bl.id).then(rx => this.triggerDownload(rx.data, bl.number + ".pdf") ).catch(error => this.toastError(error));
                        else this.$toast.success(this.$t('form.created_x', [this.$t('navigation.bills_of_lading')]));
                        this.$refs.proforma.setQuoteInvoiceLines();
                    });
                }
                else{
                    return billofladingService.update(bl).then((response) => {
                        if(download) return jobService.downloadPDF('bill-of-lading', bl.id).then(rx => this.triggerDownload(rx.data, bl.number + ".pdf") ).catch(error => this.toastError(error));
                        else this.$toast.success(this.$t('form.updated_x', [this.$t('navigation.bills_of_lading')]));
                        this.$refs.proforma.setQuoteInvoiceLines();
                    });
                }
            },

            addBooking(){
                this.creatingNewBooking = true;
                document.getElementById("confirmorrequest").classList.remove('d-none');
                this.$nextTick(() => this.$refs.bookingCreate.validateTabOne());
            },
            qnext(e){
                const nextStep = e.target.id == "nextStep" ? e.target.innerHTML : e.target.lastElementChild.innerHTML;
                document.querySelector("#quotations > ul").children[Number(nextStep) - 1].children[0].click();
            },
            setCopyInvoiceData(data){
                window.setLatestInvoice = true;
                if(!window.createNewJobInvoice){
                    this.job.bookings[this.selectedBooking].invoices[this.job.bookings[this.selectedBooking].invoices.length - 1] = data;
                    this.activeInvoice = data.id;
                    const selectedBooking = this.selectedBooking + '';
                    this.$nextTick(() => {
                        document.getElementById('booking-4-'+selectedBooking+'-999').nextElementSibling.click();
                    });
                }
                else{
                    window.storedInvoiceData = data;
                }
            },

            showInvoicePDF(){ return this.$refs.invoiceref.showPDF(true, false) },
            saveInvoicePDF(){ return this.$refs.invoiceref.showPDF(false, false) },
            finishEmail(){ return this.$refs.invoiceref.email() },
            finishWithoutEmail(){ return this.$refs.invoiceref.finishWithoutEmail() },
            saveBooking(){ return this.createBooking() },
            requestBooking(){ return this.createBooking(true) },
            getAutoSaveEnabled(){ return true },
            hardCopy(obj){ return JSON.parse(JSON.stringify(obj)) },
            downloadBookingRequest(){ 
                const newBooking = this.job.bookings.at(-1);
                const bookingJobUnits = newBooking.job_unit.map(ju => ju.id).join('-');
                return jobService.downloadPDF('booking-request', bookingJobUnits).then(rx => this.triggerDownload(rx.data, newBooking.number + "_booking_request.pdf") ).catch(error => this.toastError(error));
            },

            setSlider(hide, disable){
                this.hide_surcharges = hide;
                this.hide_surcharges_disabled = disable;
            },
            setHideSurcharge(e){
                this.$refs.ItemForm.quotation.hide_surcharges = this.hide_surcharges;
                this.$refs.ItemForm.calcCostSummary(this.hide_surcharges);
            },
            close(){
                if(this.isEditing && !confirm(this.$t('form.are_you_sure_close')))
                    return;
                if(window.location.hash && !this.dontRemoveHash && !this.fromExport){
                    window.location.hash = '';
                }
                this.isExiting = true;
                this.shouldAskOnClose = false;
                const typeaheads = document.getElementById('formModal').querySelectorAll('.simple-typeahead-reset');
                typeaheads.forEach((el) => el.nextElementSibling.classList.remove('is-invalid'));
                const isNotFromJobs = this.$route.name == 'Bills of Lading' || this.$route.name == 'Invoices';
                if(!this.fromExport && !isNotFromJobs){
                    document.querySelector("#modal-close-button").click();
                    const dSpan = document.getElementById('detailsSpan')
                    if(dSpan) dSpan.innerHTML = '';
                }
                else if(isNotFromJobs){
                    const el = document.getElementById('job-popup').children[0];
                    el.classList.remove('show', 'd-block');
                    el.style.display = 'none';
                    const el2 = document.querySelector('.modal-backdrop');
                    if (el2) el2.remove();
                    document.body.classList.remove('modal-open');
                    document.body.style.overflow = 'auto';
                    document.body.style.paddingRight = '0';
                    this.$nextTick(() => {
                        this.$parent.jobLinkedBL = false;
                        this.$parent.$parent.$forceUpdate();
                        document.body.style.overflow = 'auto';
                    });
                }
                else{
                    document.getElementById('job-popup').children[0].classList.remove('show', 'd-block');
                    document.getElementById("job-dialog").close();
                    if(this.resetMPStatus)
                        this.$parent.resetAfterJobChanges();
                }
                if(window.activeChannel){
                    window.Echo.leaveChannel(window.activeChannel);
                }
                window.autocompleteActive = false;
                this.bookingToOpen = null;
            },
            
            addFiles(fileInfo){
                fileService.upload({"file_info": fileInfo, "id": this.job.id, "model": 'job'}).then(response => {
                    this.job.file_info.unshift(response.data); 
                }).catch(error => console.log('error', error));
            },

            checkData(){
                this.dataItemsLoaded++;
                if(this.dataItemsLoaded >= this.dataItemsToLoad){
                    if(this.isCreatingNew) this.reset(false);
                    else this.setData(false);
                }
            }

        },
        mounted() {
            this.conditionOptions = dataStore.getters.getConditions;
            this.quantityOptions = [];
            for(let i = 1; i <= 100; i++)
                this.quantityOptions.push({id: i, name: i});
        }
    }
</script>

<style scoped>
    .badge {
        padding: .9rem .7rem;
        color: white;
        border-radius: 100rem;
        width: 40px
    }
    .badge.clickable { background-color: #ddd }
    .badge.clickable:hover { background-color: #d3d3d3 }
    #myTab2{
        position: sticky;
        top: 0;
        width: 100%;
    }
    .nav-link-alt { 
        background: #fff;
        border: 1px solid #005da3;
        border-right: none !important;
        color: #005da3;
        border-radius: 3px 0 0 3px;
        padding: 3px 4px;
        width: 100%;
        text-align: left;
        padding-left: 7px;
        padding-right: 0px;
    }
    .nav-link-alt.bg-success { 
        border-color: #43A047 !important;
        color: #fff !important;
    }
    .nav-link-alt:hover { 
        background: #eee;
    }
    .nav-link-alt.active { 
        background: #005da3 !important;
        color: #fff !important;
    }
    #myTabContent2{
        min-height: calc(90vh - 264px) !important;
        border-color: #005da3 !important;
        border-radius: 3px
    }
    .job-nav, .job-nav-alt {
        padding: 1.25rem 1.4rem 0 1.4rem;
    }
    .job-nav {
        color: #005da3;
    }
    .job-nav[data-active="true"], .job-nav-alt[data-active="true"], .job-nav:hover, .job-nav-alt:hover {
        background-color: #005da3;
        color: white;
        cursor: pointer;
    }
    .addon{
        border-radius: 100px;
        padding: 0px 6px;
        display: inline-block;
        font-size: .85rem !important;
        translate: 0 -1px;
    }
    .job-nav-alt[data-active="false"] .addon{
        background-color: #005da3;
        color: white;
    }
    .job-nav-alt[data-active="true"] .addon, .job-nav-alt:hover .addon{
        background-color: white !important;
        color: #005da3 !important;
    }
    #jobTab{
        background-color: #005da3;
    }
    #jobTabContent{
        height: calc(100vh - 200px);
        overflow-y: auto;
    }
    .job-nav-button{
        margin-top: 1rem;
        background-color: white;
        color: #005da3;
        border-radius: 3px 3px 0 0 !important;
        box-shadow: none !important;
    }
    .job-nav-button:not(.active){
        background-color: transparent;
        color: white
    }
    .job-nav-button.pending:not(.active){
        background-color: var(--bs-success) !important
    }
    .fixed-footer{
        position: fixed;
        bottom: 5px;
        width: 1398px;
        background-color: white;
        padding-top: 4px;
        translate: -15px 0;
        z-index: -1;
        padding-bottom: 6px;
        left: calc(15.5px + (100vw - 1400px)/2)
    }
    #job-popup.exiting .fixed-footer{
        display: none !important
    }
    #newsavebuttons.disabled{
        pointer-events: none;
        opacity: 0.5;
    }
</style>