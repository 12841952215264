<template>

    <div class="d-flex" id="detailswrapper" :class="{'is-operator': booking.is_operator}" v-if="isEditing && booking">
        <div class="col-10">
            <h4 class="w-100 mb-2"> {{$t('navigation.bookings') + ' ' + booking.number}}&ensp;<sub>({{booking.job_unit.length + ' ' + $tc('navigation.units', booking.job_unit)}})</sub> 
            <button class="btn btn-primary ms-3" @click="sendToTeams(booking.id, 'netvibes')" v-if="booking.number.includes('TEST')">Test Netvibes webhook</button>
            <button class="btn btn-primary ms-3" @click="sendToTeams(booking.id, 'powerautomate')" v-if="isNMTHQ">{{booking.number.includes('TEST') ? 'Test' : ''}} Powerautomate webhook</button>
            </h4>
            <div class="voyage-block d-flex w-100 mb-1 pe-2">
                <template v-for="(voyageBit, vindex) in booking.voyage_info" :key="vindex">
                    <div class="vport" v-if="vindex % 2 == 0">
                        <i></i>
                    </div>
                    <div class="vconn" :class="{'inactive': voyageBit.index != currentLeg && detailsOpen}" v-else>
                        <span> 
                            <img v-if="voyageBit.carrier_logo" :src="voyageBit.carrier_logo" height="48" /><br/> {{voyageBit.name}} 
                        </span>
                        <div>
                            <i :style="{'width': 'calc('+(100 - voyageBit.completion) + '% - '+(voyageBit.completion != 0 && voyageBit.completion != 100 ? 17 : 0)+'px)'}" v-show="voyageBit.completion != 100"></i>
                            <b v-show="voyageBit.completion != 0 && voyageBit.completion != 100" class="fa fa-ship"></b>
                            <i :style="{'width': voyageBit.completion + '%'}" v-show="voyageBit.completion != 0"></i>
                        </div>
                    </div>
                </template>
            </div>
            <div class="voyage-block d-flex w-100 mb-2 pe-2">
                <template v-for="(voyageBit, vindex) in booking.voyage_info" :key="vindex">
                    <label class="vlabel" v-if="vindex % 2 == 0" :class="{'inactive': detailsOpen && ((currentLeg == 0 && vindex == 4) || (currentLeg == 1 && vindex == 0)) }">
                        <div class="fw-bold">{{voyageBit.port}}</div>
                        <span>{{formatDate(voyageBit.date, 'longDateNoYear')}}</span>
                    </label>
                    <div class="vconn" v-else></div>
                </template>
            </div>
            <div class="d-flex unit-details-acc text-uppercase text-secondary col-form-label-sm" style="width: calc(100% - 2rem)">
                <div class="col-0p5"> <input class="form-check-input" :id="'check-booking-'+uIndex+'-'+booking.id+'-all'" type="checkbox" @click="(e) => addPDFUnits(unitsForPDF.length == booking.job_unit.length)" :checked="unitsForPDF.length == booking.job_unit.length" /> </div>
                <div class="col-2x"> {{$t('navigation.makes')}}/{{$t('navigation.models')}} </div>
                <div class="col-2x license-plate"> {{$t('overview.vin')}} </div>
                <div class="col-2x ps-0 text-ellipsis">{{$t('navigation.commodities')}}</div>
                <div class="col-3x"> {{$t('overview.state')}} </div>
                <div class="col-3x text-ellipsis"> {{$t('overview.status')}} </div>
            </div>
            <div class="accordion" :id="'a' + booking.id + '-accordion'" v-for="unit, uIndex in booking.job_unit" :key="uIndex">
                <div class="accordion-container">
                    <div class="accordion-header d-flex w-100" :id="'heading-'+uIndex">
                        <div class="col-auto unit-details-acc" style="width: calc(100% - 1rem)" role="clickable" @click="openCollapser">
                            <div class="d-flex">
                                <div class="col-0p5 unit-detail-checkbox"> 
                                    <FormItem :id="'check-booking-'+uIndex+'-'+booking.id+'-0'" type="checkbox" @onclick="(e) => addPDFUnit(unit, !e.target.checked)" :disabled="unit.is_cancelled" />
                                </div>
                                <div class="col-2x"> {{unit.make.name}} {{unit.model.name}} </div>
                                <div class="col-2x license-plate"> {{unit.chassis_number}} </div>
                                <div class="col-2x ps-0 text-ellipsis">{{ unit.commodity.name }}</div>
                                <div class="col-3x"> {{unit.condition_id == 2 ? $t('form.used') : $t('form.new')}} </div>
                                <div class="col-3x" v-if="unit.is_cancelled"> {{ $t('quote_status.cancelled') }} &ensp; <span class="col-1 text-link" @click="uncancelUnit(unit)">{{$t('form.undo')}}</span> </div>
                                <div v-else class="col-3x text-ellipsis">
                                    {{ statuses[detailsOpen ? unit['status_' + currentLeg] : unit.status_0] }} &nbsp;
                                    <span class="unit-label bg-danger" v-show="unit['is_priority_' + (detailsOpen ? currentLeg : '0')]">P</span>
                                    <span class="unit-label bg-success" v-show="unit['is_cleared_' + (detailsOpen ? currentLeg : '0')]">C</span>
                                    <span class="unit-label bg-info" v-show="unit['is_on_hold_' + (detailsOpen ? currentLeg : '0')]">H</span>
                                    <span class="unit-label bg-dark" v-show="unit['is_delivered_' + (detailsOpen ? currentLeg : '0')]">D</span>
                                    <template v-if="unit.remarks_string">
                                        <span class="unit-label bg-warning" @mouseenter="showTooltip" @mouseleave="hideTooltip">R</span>
                                        <pre class="remark-blurb">{{unit.remarks_string}}</pre>
                                    </template>
                                    <span class="unit-label bg-danger" style="width: 2rem" v-show="unit.is_new">New</span>
                                </div>
                            </div>
                            <div class="d-flex" v-for="sunit in unit.stacked_units" :key="sunit.id" :class="{'cancelled': unit.is_cancelled}">
                                <div class="col-0p5 fa-hybrid" @click="unlink(sunit)">
                                    <i class="fa fa-xs" :class="{'ps-1 fa-turn-down-right': sunit.stacking_type == 'stacked' || sunit.stacking_type == 'stacked-connected', 'fa-link': sunit.stacking_type == 'connected'}"></i>
                                </div>
                                <div class="col-2x"> {{sunit.make.name}} {{sunit.model.name}} </div>
                                <div class="col-2x license-plate"> {{sunit.chassis_number}} </div>
                                <div class="col-2x ps-0 text-ellipsis">{{ sunit.commodity.name }}</div>
                                <div class="col-3x"> {{sunit.condition_id == 2 ? $t('form.used') : $t('form.new')}} </div>
                            </div>
                        </div>
                        <button type="button" class="acc-header-item col header-arrow" :class="{'collapsed': !unit.open}" :id="'ub-'+unit.id" @click="getImages(unit, $event)" aria-expanded="false"></button>
                    </div>

                    <div class="accordion-collapse collapse show" :id="'a' + booking.id + '-collapse-'+uIndex" :aria-labelledby="'heading-'+uIndex" :data-bs-parent="'#a'+booking.id+'-accordion'">
                        <div class="accordion-body" v-if="unit.open">
                            <div class="fw-bold mb-1">{{$t('operations.unit_details')}}</div>
                            <div class="row">
                                <label class="col-sm-2 col-form-label-sm">
                                    <div>{{$tc('navigation.commodities', 1)}}</div>
                                    <FormItem v-model="unit.commodity_id" type="select" :options="commodities" @onchange="autoUpdateUnit(unit)" />
                                    <div class="col-form-label-sm">{{ $t('sales.build_year') }}</div>
                                    <FormItem v-model="unit.construction_year" type="number" @onchange="autoUpdateUnit(unit)" />
                                </label>
                                <label class="col-sm-1p5 col-form-label-sm">
                                    <div>{{$t('form.new')}}</div>
                                    <FormItem v-model="unit.condition_id" type="slider" :options="[1,2]" @onchange="autoUpdateUnit(unit)" />
                                    <div class="col-form-label-sm"> {{ $t('navigation.colours') }}</div>
                                    <FormItem v-model="unit.colour_id" type="select" :options="colours" @onchange="autoUpdateUnit(unit)" />
                                </label>
                                <div class="col-sm-2 col-form-label-sm">
                                    <div>{{$t('navigation.makes')}}</div>
                                    <FormItem v-model="unit.unit_make_id" type="livesearch" :service="makeService" :content="unit.make ? unit.make.name : ''" @onchange="autoUpdateUnit(unit)" />
                                    <div class="col-form-label-sm">{{ $t('navigation.hs_codes') }}</div>
                                    <div> <FormItem v-model="unit.hs_code" type="text" @onchange="autoUpdateUnit(unit)" /> </div>
                                </div>
                                <div class="col-sm-2 col-form-label-sm">
                                    <div>{{$t('navigation.models')}}</div>
                                    <FormItem v-model="unit.unit_model_id" type="livesearch" :service="modelService" :content="unit.model ? unit.model.name : ''" @onchange="autoUpdateUnit(unit)" :extraOptions="{'make_id': unit.unit_make_id}" />
                                </div>
                                <div class="col-sm-3 col-form-label-sm">
                                    <div>{{ $t('sales.vin') }}</div>
                                    <input v-model="unit.chassis_number" class="form-control form-control-sm license-plate" @keydown="licensePlateAlterInput" @change="toUpper($event.target, unit); autoUpdateUnit(unit)" />
                                </div>
                                <div class="col-sm-2 col-form-label-sm">
                                    <template v-if="licensePlateRequired">
                                        <div>{{ $t('sales.license_plate') }}</div>
                                        <input v-model="unit.license_plate" class="form-control form-control-sm license-plate" @keydown="licensePlateAlterInput" @change="toUpper($event.target); autoUpdateUnit(unit)" />
                                    </template>
                                    <div v-else style="height: 47px"></div>
                                </div>
                            </div>
                            <div class="border-left">
                                <div class="row" v-for="sunit in unit.stacked_units" :key="sunit.id">
                                    <label class="col-sm-auto col-form-label-sm" style="width: 15.7%">
                                        <div>{{$tc('navigation.commodities', 1)}}</div>
                                        <FormItem v-model="sunit.commodity_id" type="select" :options="commodities" @onchange="autoUpdateUnit(unit)" />
                                        <div class="col-form-label-sm">{{ $t('sales.build_year') }}</div>
                                        <FormItem v-model="sunit.construction_year" type="number" @onchange="autoUpdateUnit(unit)" />
                                    </label>
                                    <label class="col-sm-1p5 col-form-label-sm">
                                        <div>{{$t('form.new')}}</div>
                                        <FormItem v-model="sunit.condition_id" type="slider" :options="[1,2]" @onchange="autoUpdateUnit(unit)" />
                                        <div class="col-form-label-sm"> {{ $t('navigation.colours') }}</div>
                                        <FormItem v-model="sunit.colour_id" type="select" :options="colours" @onchange="autoUpdateUnit(unit)" />
                                    </label>
                                    <label class="col-sm-2 col-form-label-sm" style="width: 17%">
                                        <div>{{$t('navigation.makes')}}</div>
                                        <FormItem v-model="sunit.unit_make_id" type="livesearch" :service="makeService" :content="sunit.make ? sunit.make.name : ''" @onchange="autoUpdateUnit(unit)" />
                                        <div class="col-form-label-sm">{{ $t('navigation.hs_codes') }}</div>
                                        <FormItem v-model="sunit.hs_code" type="text" @onchange="autoUpdateUnit(unit)" />
                                    </label>
                                    <label class="col-sm-2 col-form-label-sm" style="width: 17%">
                                        <div>{{$t('navigation.models')}}</div>
                                        <FormItem v-model="sunit.unit_model_id" type="livesearch" :service="modelService" :content="sunit.model ? sunit.model.name : ''" @onchange="autoUpdateUnit(unit)" :extraOptions="{'make_id': sunit.unit_make_id}" />
                                    </label>
                                    <div class="col-sm-3 col-form-label-sm">
                                        <div>{{ $t('sales.vin') }}</div>
                                        <input v-model="sunit.chassis_number" class="form-control form-control-sm license-plate" @keydown="licensePlateAlterInput" @change="toUpper($event.target, sunit); autoUpdateUnit(unit)" />
                                    </div>
                                    <div class="col-sm-2 col-form-label-sm">
                                        <template v-if="licensePlateRequired">
                                            <div>{{ $t('sales.license_plate') }}</div>
                                            <input v-model="sunit.license_plate" class="form-control form-control-sm license-plate" @keydown="licensePlateAlterInput" @change="toUpper($event.target); autoUpdateUnit(unit)" />
                                        </template>
                                        <div v-else style="height: 47px"></div>
                                    </div>
                                </div>
                            </div>

                            <ul class="nav nav-main nav-tabs mt-2" :id="'jobUnitTab-'+unit.id" role="tablist">
                                <li class="nav-item" role="presentation" v-for="(item, itemuIndex) in unit.job_booking_voyage_job_units" :key="itemuIndex">
                                    <button class="btn job-nav-button" :class="{'active': currentLeg == itemuIndex}" type="button" 
                                            role="tab" :aria-controls="'juv-'+unit.id+'-'+item.id" @click="currentLeg = itemuIndex">
                                        {{ booking.voyages[itemuIndex] ? booking.voyages[itemuIndex].name : (booking.voyages[0] ? booking.voyages[0] : {number: '???'}).number}}
                                    </button>
                                </li>
                            </ul>
                            <div class="tab-content px-3 pb-2" :id="'jobUnitTabContent'+unit.id">
                            <div class="tab-pane" v-for="(item, itemuIndex) in unit.job_booking_voyage_job_units" :key="itemuIndex" role="tabpanel" :class="{'show active': currentLeg == itemuIndex}">
                                <div class="row mt-3" :class="{'checked': unit.is_checked,
                                                               'green-measured': (item.unit_measurement_type_id == 1 || (item.unit_measurement_type_id_operator == 1 && !item.unit_measurement_type_id)), 
                                                               'green-weight': (item.unit_weight_type_id == 1 || (item.unit_weight_type_id_operator == 1 && !item.unit_weight_type_id))}">
                                    <div class="col-sm-auto col-form-label-sm" style="width: 18%">
                                        <div class="fs-6">
                                            <span class="fw-bold">{{$t('operations.dimensions')}}&ensp;</span>
                                            <i class="fa fa-check text-success fw-bold" v-show="item.is_checked && item.are_measurements_chosen"></i>
                                            <span v-show="!booking.is_operator && !item.is_checked" class="unit-label bg-gwarning popover-trigger me-2" @mouseenter="showTooltip" @mouseleave="hideTooltip">
                                                <i class="fal fa-triangle-exclamation"></i>
                                            </span>
                                            <span class="remark-blurb popover-bubble">The operator has not selected the Buy measurements yet.</span>
                                        </div>
                                        <label v-if="item.is_checked && item.purchase_measurement_type_id && !booking.bookerIsOperator" class="pt-1">
                                            <input type="radio" :name="'juvrad-'+unit.id+'-'+item.id" class="form-check-input me-2" v-model="item.unit_measurement_type_id" :value="1" :disabled="job.user_customer.id || (!booking.is_operator && !item.is_checked)" />
                                            <span>{{$t('operations.dimensions_booked')}}</span>
                                            <i class="fa fa-info-circle ms-2" v-show="item.unit_measurement_type_id_operator == 1" @mouseenter="showTooltip" @mouseleave="hideTooltip"></i>
                                            <pre class="remark-blurb">{{$t('operations.used_by_operator')}}</pre>
                                        </label>
                                        <label v-else class="pt-1">
                                            <input type="radio" :name="'juvrad-'+unit.id+'-'+item.id" class="form-check-input me-2" v-model="item.unit_measurement_type_id" :value="1" :disabled="job.user_customer.id || (!booking.is_operator && !item.is_checked)" />
                                            <span>{{$t('operations.dimensions_booked')}}</span>
                                        </label>
                                    </div>
                                    <label class="col col-form-label-sm col-dimensions">
                                        {{$t('form.length')}}<br>
                                        <FormItem v-model="unit.booked_measurement.length" type="number-with-tags" endTag="cm" :disabled="disableMeasured || (booking.measurements_fixed && item.status_id == 4)" @onchange="unit.altBooked = true" />
                                    </label>
                                    <label class="col col-form-label-sm col-dimensions">
                                        {{$t('form.width')}}<br>
                                        <FormItem v-model="unit.booked_measurement.width" type="number-with-tags" endTag="cm" :disabled="disableMeasured || (booking.measurements_fixed && item.status_id == 4)" @onchange="unit.altBooked = true" />
                                    </label>
                                    <label class="col col-form-label-sm col-dimensions">
                                        {{$t('form.height')}}<br>
                                        <FormItem v-model="unit.booked_measurement.height" type="number-with-tags" endTag="cm" :disabled="disableMeasured || (booking.measurements_fixed && item.status_id == 4)" @onchange="unit.altBooked = true" />
                                    </label>
                                    <label class="col col-form-label-sm col-dimensions">
                                        CBM<br>
                                        <div class="input-group">
                                            <div class="form-control form-control-sm disabled measure-field">{{calcCBM(unit.booked_measurement)}}</div>
                                            <span class="input-group-text px-2 py-0" style="font-size: .75rem">cm</span>
                                        </div>
                                    </label>
                                    <div class="col-sm-auto col-form-label-sm" style="width: 12%">
                                        <div class="fw-bold fs-6">&ensp;</div>
                                        <label v-if="item.is_checked && item.purchase_weight_type_id && !booking.bookerIsOperator" class="pt-1">
                                            <input type="radio" :name="'juvrax-'+unit.id+'-'+item.id" class="form-check-input me-2" v-model="item.unit_weight_type_id" :value="1" :disabled="job.user_customer.id || (!booking.is_operator && !item.is_checked)" />
                                            <span>{{$t('operations.dimensions_booked')}}</span>
                                            <i class="fa fa-info-circle ms-2" v-show="item.unit_weight_type_id_operator == 1" @mouseenter="showTooltip" @mouseleave="hideTooltip"></i>
                                            <pre class="remark-blurb">{{$t('operations.used_by_operator')}}</pre>
                                        </label>
                                        <label v-else class="pt-1">
                                            <input type="radio" :name="'juvrax-'+unit.id+'-'+item.id" class="form-check-input me-2" v-model="item.unit_weight_type_id" :value="1" :disabled="job.user_customer.id || (!booking.is_operator && !item.is_checked)" />
                                            <span>{{$t('operations.dimensions_booked')}}</span>
                                        </label>
                                    </div>
                                    <label class="col col-form-label-sm col-weight">
                                        {{$t('form.weight')}}<br>
                                        <FormItem v-model="unit.booked_measurement.weight" type="number-with-tags" endTag="kg" :disabled="disableMeasured || (booking.measurements_fixed && item.status_id == 4)" @onchange="unit.altBooked = true" />
                                    </label>
                                </div>
                                <div class="row" :class="{'d-none': job.user_customer.id && item.unit_measurement_type_id != 2, 'checked': item.is_checked,
                                                            'green-measured': (item.unit_measurement_type_id == 2 || (item.unit_measurement_type_id_operator == 2 && !item.unit_measurement_type_id)), 
                                                            'green-weight': (item.unit_weight_type_id == 2 || (item.unit_weight_type_id_operator == 2 && !item.unit_weight_type_id))}">
                                    <div class="col-sm-auto col-form-label-sm" style="width: 18%">
                                        <label v-if="item.is_checked && item.purchase_measurement_type_id && !booking.bookerIsOperator" class="pt-1">
                                            <input type="radio" :name="'juvrad-'+unit.id+'-'+item.id" class="form-check-input me-2" v-model="item.unit_measurement_type_id" :value="2" :disabled="job.user_customer.id || (!booking.is_operator && !item.is_checked)" />
                                            <span>{{$t('operations.dimensions_measured')}}</span>
                                            <i class="fa fa-info-circle ms-2" v-show="item.unit_measurement_type_id_operator == 2" @mouseenter="showTooltip" @mouseleave="hideTooltip"></i>
                                            <pre class="remark-blurb">{{$t('operations.used_by_operator')}}</pre>
                                        </label>
                                        <label v-else class="pt-1">
                                            <input type="radio" :name="'juvrad-'+unit.id+'-'+item.id" class="form-check-input me-2" v-model="item.unit_measurement_type_id" :value="2" :disabled="job.user_customer.id || (!booking.is_operator && !item.is_checked)" />
                                            <span>{{$t('operations.dimensions_measured')}}</span>
                                        </label>
                                    </div>
                                    <label class="col col-form-label-sm pt-0 col-dimensions">
                                        <FormItem v-model="item.measured_measurement.length" type="number-with-tags" endTag="cm" class="mt-1" :disabled="disableMeasured || (booking.measurements_fixed && item.status_id == 4)" />
                                    </label>
                                    <label class="col col-form-label-sm pt-0 col-dimensions">
                                        <FormItem v-model="item.measured_measurement.width" type="number-with-tags" endTag="cm" class="mt-1" :disabled="disableMeasured || (booking.measurements_fixed && item.status_id == 4)" />
                                    </label>
                                    <label class="col col-form-label-sm pt-0 col-dimensions">
                                        <FormItem v-model="item.measured_measurement.height" type="number-with-tags" endTag="cm" class="mt-1" :disabled="disableMeasured || (booking.measurements_fixed && item.status_id == 4)" />
                                    </label>
                                    <label class="col col-form-label-sm pt-1 col-dimensions">
                                        <div class="input-group">
                                            <div class="form-control form-control-sm disabled measure-field">{{calcCBM(item.measured_measurement)}}</div>
                                            <span class="input-group-text px-2 py-0" style="font-size: .75rem">cm</span>
                                        </div>
                                    </label>
                                    <div class="col-sm-auto col-form-label-sm" style="width: 12%">
                                        <label v-if="item.is_checked && item.purchase_weight_type_id && !booking.bookerIsOperator" class="pt-1">
                                            <input type="radio" :name="'juvrax-'+unit.id+'-'+item.id" class="form-check-input me-2" v-model="item.unit_weight_type_id" :value="2" :disabled="job.user_customer.id || (!booking.is_operator && !item.is_checked)" />
                                            <span>{{$t('operations.dimensions_measured')}}</span>
                                            <i class="fa fa-info-circle ms-2" v-show="item.unit_weight_type_id_operator == 2" @mouseenter="showTooltip" @mouseleave="hideTooltip"></i>
                                            <pre class="remark-blurb">{{$t('operations.used_by_operator')}}</pre>
                                        </label>
                                        <label v-else class="pt-1">
                                            <input type="radio" :name="'juvrax-'+unit.id+'-'+item.id" class="form-check-input me-2" v-model="item.unit_weight_type_id" :value="2" :disabled="job.user_customer.id || (!booking.is_operator && !item.is_checked)" />
                                            <span>{{$t('operations.dimensions_measured')}}</span>
                                        </label>
                                    </div>
                                    <label class="col col-form-label-sm pt-0 col-weight">
                                        <FormItem v-model="item.measured_measurement.weight" type="number-with-tags" endTag="kg" class="mt-1" :disabled="disableMeasured || (booking.measurements_fixed && item.status_id == 4)" />
                                    </label>
                                </div>
                                <div class="fw-bold mt-3 mb-1">{{$tc('form.reference', 2)}}</div>
                                <div class="row">
                                    <label class="col-sm-3 col-form-label-sm">
                                        {{$t('operations.ref_customer')}}<br> <FormItem v-model="unit.customer_reference" />
                                    </label>
                                    <label class="col-sm-2 col-form-label-sm">
                                        {{$t('operations.ref_carrier')}}<br> <FormItem v-model="item.carrier_reference" :disabled="job.user_customer.id" />
                                    </label>
                                    <label class="col-sm-2 col-form-label-sm">
                                        {{$t('operations.ref_port')}}<br> <FormItem v-model="item.port_reference" :disabled="job.user_customer.id" />
                                    </label>
                                    <label class="col-sm-2 col-form-label-sm">
                                        {{$t('operations.ref_customs')}}<br> <FormItem v-model="item.customs_reference" :disabled="job.user_customer.id" />
                                    </label>
                                    <label class="col-form-label-sm" style="flex: 0 0 auto; width: 11%">
                                        <br> <FormItem v-model="item.customs_type_id" type="select" :options="customsRefOptions" :disabled="job.user_customer.id" />
                                    </label>
                                    <label class="col col-form-label-sm">
                                        {{$t('overview.location')}}<br> <FormItem v-model="item.location" :disabled="job.user_customer.id" />
                                    </label>
                                </div>
                                <div class="row mt-3">
                                    <label class="col-sm-1p4 col-form-label-sm pe-1">
                                        <span class="fw-bold fs-6">{{$t('overview.status')}}</span><br>
                                        <FormItem :id="'clear-' + item.id" :label="$t('operations.status_cleared')" v-model="item.is_cleared" type="checkbox" :disabled="job.user_customer.id" />
                                    </label>
                                    <label class="col-sm-1p4 col-form-label-sm mt-4 pe-1">
                                        <FormItem :id="'surv-' + item.id" :label="$t('operations.status_surveyed')" v-model="item.is_surveyed" type="checkbox" :disabled="job.user_customer.id" />
                                    </label>
                                    <label class="col-sm-1p4 col-form-label-sm mt-4 pe-1">
                                        <FormItem :id="'hold-' + item.id" :label="$t('operations.status_on_hold')" v-model="item.is_on_hold" type="checkbox" :disabled="job.user_customer.id" />
                                    </label>
                                    <label class="col-sm-1p4 col-form-label-sm mt-4 pe-1">
                                        <FormItem :id="'prio-' + item.id" :label="$t('operations.status_priority')" v-model="item.is_priority" type="checkbox" :disabled="job.user_customer.id" />
                                    </label>
                                    <label class="col-sm-2 col-form-label-sm">
                                        {{$t('operations.date_delivered')}}
                                        <FormItem type="datepicker" v-model="item.delivered_date" :disabled="job.user_customer.id" />
                                    </label>
                                    <label class="col-sm-2 col-form-label-sm">
                                        {{$t('operations.date_loaded')}}<br>
                                        <FormItem type="datepicker" v-model="item.loaded_date" :disabled="job.user_customer.id" />
                                    </label>
                                </div>
                                <hr>

                                <div class="fw-bold mt-3 mb-1">{{ $tc('form.remark', 2) }}</div>
                                <div class="row">
                                    <FormItem type="textarea" v-model="unit.job_unit_message" style="width: 58.3%" />
                                </div>
                                <template v-for="remark in unit.remarks" :key="remark.id">
                                    <div class="fst-italic mt-1"> {{ formatDate(remark.created_at, 'dateTimeNoSeconds') }} - {{ remark.user.name }} </div>
                                    <div> <pre>{{ remark.content }}</pre> </div>
                                </template>
                                <div class="fw-bold mt-3 mb-1">{{$tc('form.pictures', 2) + ' (' + (unit.files.length + unit.file_info.length) + ')'}}</div>
                                <div class="d-flex pb-2">
                                    <div class="d-flex w-75">
                                        <input type="file" accept="image/*" multiple="" class="d-none" @change="uploadDragDropFile($event, unit, true)" />
                                        <div class="image-drop" @dragover="dropzoneDragOver" @dragleave="dropzoneDragLeave" @drop="uploadDragDropFile($event, unit)" @click="$event.target.previousElementSibling.click()">
                                            {{$t('form.drop_images')}}
                                        </div>
                                        <div v-for="image, imageIndex in unit.files" :key="imageIndex" class="uploaded-image" :style="{backgroundImage: 'url(' + image.url + ')'}">
                                            <i class="fa fa-download" @click="downloadImage(image)"></i>
                                            <i class="fa fa-close" @click="deleteUploadedImage(unit, imageIndex)"></i>
                                        </div>
                                        <div v-for="image, imageIndex in unit.file_info" :key="imageIndex" class="uploaded-image" :style="{backgroundImage: 'url(' + image.url + ')'}">
                                            <i class="fa fa-close" @click="unit.file_info.splice(imageIndex, 1)"></i>
                                        </div>
                                    </div>
                                    <div class="d-flex w-25 justify-content-end">
                                        <button class="btn btn-success h-50 align-self-end px-4" type="button" @click="updateUnits(unit, item)">
                                            {{ $t('form.save') }}
                                        </button>
                                    </div>
                                </div> 
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <dialog id="confirm-dialog">
            <p class="py-4">Do you want to use these dimensions for Invoicing?</p>
            <form method="dialog" class="d-flex justify-content-between">
                <button class="btn btn-success col-5" @click="confirmDialog(true)">{{ $t('form.yes') }}</button>
                <button class="btn btn-danger col-5" @click="confirmDialog(false)">{{ $t('form.no') }}</button>
            </form>
        </dialog>

        <div class="col ps-1">
            <h6 class="fw-bold">Booking actions</h6>
            <button type="button" @click="$parent.changeBookingCreate('mode_add_unit')" class="w-100 mt-1 btn btn-sm btn-pdf" :disabled="booking.disable_booking_actions">
                <i class="fa fa-plus"></i>&ensp;{{ $t('form.add', [$tc('navigation.units', 2)]) }}
            </button>
            <button type="button" @click="$parent.changeBookingOpen('mode_import_unit')" class="w-100 mt-1 btn btn-sm btn-pdf" :disabled="booking.disable_booking_actions">
                <i class="fa fa-file-import"></i>&ensp;{{$t('operations.mode_import_unit')}}
            </button>
            <button type="button" @click="$parent.changeBookingCreate('mode_change_ports')" class="w-100 mt-1 btn btn-sm btn-pdf" :disabled="booking.disable_booking_actions">
                <i class="fa fa-repeat"></i>&ensp;{{$t('operations.mode_change_ports')}}
            </button>
            <button type="button" @click="$parent.changeBookingOpen('mode_connect_units')" class="w-100 mt-1 btn btn-sm btn-pdf" :disabled="booking.disable_booking_actions">
                <i class="fa fa-link"></i>&ensp;{{$t('operations.mode_connect_units')}}
            </button>
            <button type="button" @click="$parent.changeBookingOpen('mode_stacked_unit')" class="w-100 mt-1 btn btn-sm btn-pdf" :disabled="booking.disable_booking_actions">
                <i class="fa fa-cars"></i>&ensp;{{$t('operations.mode_stacked_unit')}}
            </button>
            <br/><br/>

            <h6 class="fw-bold">{{$t('navigation.documentation')}}</h6>
            <FormItem type="download" :label="$t('operations.all_bls')" :service="downloadAllBLs" :options="{fullWidth: true, small: true, margin: true}" v-if="!job.user_customer.id" />
            <FormItem type="download" :label="$t('operations.excel_list')" :service="downloadExcelList" :options="{fullWidth: true, small: true, margin: true}" startTag="fa fa-file-excel" />
            <FormItem type="download" :label="$t('operations.booking_summary') + (unitsForPDF.length > 0 ? (' ('+unitsForPDF.length+')') : '')" :disabled="unitsForPDF.length == 0" 
                     :service="downloadBookingSummary" :options="{fullWidth: true, small: true, margin: true}" />
            <FormItem type="download" :label="$t('operations.booking_request') + (unitsForPDF.length > 0 ? (' ('+unitsForPDF.length+')') : '')" :disabled="unitsForPDF.length == 0" 
                     :service="downloadBookingRequest" :options="{fullWidth: true, small: true, margin: true}" v-if="!job.user_customer.id" />
            <FormItem type="download" :label="$t('operations.delivery_instructions') + (unitsForPDF.length > 0 ? (' ('+unitsForPDF.length+')') : '')" :disabled="unitsForPDF.length == 0" 
                     :service="downloadDeliveryInstructions" :options="{fullWidth: true, small: true, margin: true}" />
            <FormItem type="download" :label="$t('operations.master_bl') + (unitsForPDF.length > 0 ? (' ('+unitsForPDF.length+')') : '')" :disabled="unitsForPDF.length == 0" 
                     :service="downloadMasterBL" :options="{fullWidth: true, small: true, margin: true}" v-if="!job.user_customer.id" />
            <FormItem type="download" :label="$t('operations.unit_stickers') + (unitsForPDF.length > 0 ? (' ('+unitsForPDF.length+')') : '')" :disabled="unitsForPDF.length == 0" 
                     :service="downloadUnitStickers" :options="{fullWidth: true, small: true, margin: true}" />
            <br />
            <button type="button" :disabled="unitsForPDF.length == 0" class="w-100 btn btn-sm btn-pdf" @click="emailDocumentsModal = true" v-if="!job.user_customer.id">
                <i class="fa fa-envelope"></i>&ensp;E-mail documents <span v-show="unitsForPDF.length > 0">({{unitsForPDF.length}})</span>
            </button>

            <br/><br/>
            <h6 class="fw-bold">Unit actions</h6>
            <template v-if="!job.user_customer.id">
            <button type="button" :disabled="unitsForPDF.length == 0" class="w-100 mt-1 btn btn-sm btn-status btn-prio" @click="bulkActionUnits('is_priority')">
                <i class="fa fa-square-exclamation"></i>&ensp;{{ $t('operations.status_priority') }} <span v-show="unitsForPDF.length > 0">({{unitsForPDF.length}})</span>
            </button>
            <button type="button" :disabled="unitsForPDF.length == 0" class="w-100 mt-1 btn btn-sm btn-status btn-clea" @click="bulkActionUnits('is_cleared')">
                <i class="fa fa-broom-wide"></i>&ensp;{{ $t('operations.status_cleared') }} <span v-show="unitsForPDF.length > 0">({{unitsForPDF.length}})</span>
            </button>
            <button type="button" :disabled="unitsForPDF.length == 0" class="w-100 mt-1 btn btn-sm btn-status btn-hold" @click="bulkActionUnits('is_on_hold')">
                <i class="fa fa-circle-stop"></i>&ensp;{{ $t('operations.status_on_hold') }} <span v-show="unitsForPDF.length > 0">({{unitsForPDF.length}})</span>
            </button>
            <br /><br/>
            </template>
            <button type="button" :disabled="unitsForPDF.length == 0 || selectedUnitsLoaded" class="w-100 mt-1 btn btn-sm btn-pdf" @click="cancelUnits()">
                <i class="fa fa-ban"></i>&ensp;{{ $t('operations.cancel_units') }} <span v-show="unitsForPDF.length > 0">({{unitsForPDF.length}})</span>
            </button>
            <button type="button" :disabled="unitsForPDF.length == 0 || selectedUnitsLoaded || !canChangeVoyage" class="w-100 mt-1 btn btn-sm btn-pdf" @click="changeVoyageOpen()" v-if="!job.user_customer.id">
                <i class="fa fa-repeat"></i>&ensp;{{ $t('operations.change_voyage') }} <span v-show="unitsForPDF.length > 0">({{unitsForPDF.length}})</span>
            </button>
        </div>
    </div>
    <br>



    <template v-if="emailDocumentsModal">

        <div class="modal d-block" tabindex="-1" style="background-color: rgba(1,1,1,0.4)"  @click="closeInnerModel">
            <div class="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered">
                <div class="modal-content" style="height: auto;">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel"><span>E-mail documents</span><span id="detailsSpan"></span></h5>
                        <button type="button" class="btn-close d-block" aria-label="Close" @click="closeInnerModel"></button>
                    </div>
                    <div class="modal-body" data-nofooter="false">
                        <form class="h-100" id="form-Colour" autocomplete="off">
                            Select the recipients to send the documents to:<br>
                            
                            <FormItem id="email_customer" :label="`Customer: ` + jobItem.customer.relation.addresses[0].email" v-model="email_customer" type="checkbox" />
                            <FormItem id="email_user" :label="`User: ` + getEmail" v-model="email_user" type="checkbox" />
                            <label for="email_otherlist" class="col-form-label col-form-label-sm auto-check-label">Other:</label>
                            <br>
                            <FormItem id="email_otherlist" v-model="email_otherlist" type="textarea" />
                        </form>
                    </div>
                    <div class="modal-footer"><button id="documentssendbutton" type="button" class="btn btn-success" @click="emailDocuments">{{$t('form.send')}}</button></div>
                </div>
            </div>
        </div>

    </template>

</template>

<script>
    import moment from 'moment';
    import store from '@/store/user';
    import dataStore from '@/store/data';
    import jobUnitService from '@/services/JobUnitService';
    import fileService from "@/services/FileService";
    import jobService from '@/services/JobService';
    import voyagePlanningService from '@/services/VoyagePlanningService';
    import makeService from "@/services/MakeService";
    import modelService from "@/services/ModelService";

    export default {
        props: ['jobItem', 'commodities', 'colours', 'hscodes', 'isEditing', 'loaded', 'booking', 'index'],
        name: 'JobUnitDetailsForm',
        computed: {
            job: {
                get() {
                    return this.jobItem
                }
            },
            disableMeasured() {
                return store.getters.isCustomer && ((this.jobItem.office_id && this.jobItem.office_id != this.currentOffice) || this.jobItem.user_customer.id)
            },
            getEmail () {
              return store.getters.getEmail
            },
            licensePlateRequired() { 
                return this.jobItem.port_of_loading.country.license_plate_required_export || this.jobItem.port_of_discharge.country.license_plate_required_import 
            },
            isNMTHQ() {
                return store.getters.getCompanyId == 2
            },
            selectedUnitsLoaded() {
                //Loop through unitsForPDF and check if any of these units are loaded
                //unitsForPDF only has the ID of the unit, so should look it up in the unitList
                return this.unitsForPDF.length > 0 && !this.unitList.filter(unit => this.unitsForPDF.includes(unit.id)).every(unit => unit.status_0 != 4)
            }
        },
        watch: {
            jobItem: {
                deep: true,
                handler(jobItem) {
                    this.$emit('contractcommoditychange', jobItem)
                }
            }
        },
        data() {
            return {
                detailToOpen: null,
                dropHighlightColor: 'blue',
                dropDefaultColor: '#888',
                customsRefOptions: [],
                currentOffice: null,
                currentLeg: 0,
                detailsOpen: false,
                makeService: makeService,
                modelService: modelService,

                statuses: ['', '', '', '', ''],
                unitsForPDF: [],
                unitList: [],
                voyageList: [],
                canChangeVoyage: true,
                emailDocumentsModal: false,
                saveDisabled: false,
                email_customer: false,
                email_user: false,
                email_other: false,
                email_otherlist: ""
            }
        },
        methods: {
            sendToTeams(bookingId, destination) {
                if (confirm(`Are you sure you want to trigger the ${destination} webhook?`)) {
                    jobService.sendToTeams(bookingId, destination).then(() => {
                        this.$toast.success('Sent to Teams.');
                    })
                }
            },
            dropzoneDragOver(e){
                e.preventDefault();
                if(e.target.hasAttribute('aria-hidden')){
                    e.target.style.color = this.dropHighlightColor;
                    e.target.parentElement.style.borderColor = this.dropHighlightColor;
                }
                else{
                    e.target.style.borderColor = this.dropHighlightColor;
                    e.target.style.color = this.dropHighlightColor;
                }
                
            },
            dropzoneDragLeave(e){
                if(e.target.hasAttribute('aria-hidden')){
                    e.target.style.color = this.dropDefaultColor;
                    e.target.parentElement.style.borderColor = this.dropDefaultColor;
                }
                else{
                    e.target.style.borderColor = this.dropDefaultColor;
                    e.target.style.color = this.dropDefaultColor;
                }
            },
            async uploadDragDropFile(e, item, isOnClick = false){
                e.preventDefault();
                if(!isOnClick){
                    this.dropzoneDragLeave(e);
                }
                if (isOnClick || e.dataTransfer.items) {
                    let files = e.target.files || (e.dataTransfer && e.dataTransfer.files);
                    const length = files.length + 1 - 1;
                    
                    for (var i = 0; i < length; i++) {
                        let file = files[i];

                        let fileSize = Math.ceil(file.size / 1024) + ' kb';
                        let fileType = file.type.split('/')[1];
                        let user = store.getters.getUser;
                        delete user.profile_image;
                        console.log(file.name);
                        if(file.name.match(/\.png?$|\.gif?$|\.jpg?$|\.jpeg?$|\.bmp?$|\.tif?$|\.tiff$/i)){
                            item.file_info.push({
                                'type': fileType,
                                'name': file.name.replace(/[^a-z0-9.]/gi, '_'),
                                'size': fileSize,
                                'date': moment(),
                                'user': user,
                                'blob': await this.blobToBase64(file),
                                'url': window.URL.createObjectURL(file)
                            });
                        }
                    }
                }
                if (isOnClick){
                    e.target.value = "";
                }
            },
            async blobToBase64(blob) {
                return new Promise((resolve, _) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result.substr(reader.result.indexOf(',')+1));
                    reader.readAsDataURL(blob);
                });
            },
            deleteUploadedImage(item, imageIndex){
                if(item.delete_images === undefined)
                    item.delete_images = [];
                item.delete_images.push(item.files[imageIndex].id);
                item.files.splice(imageIndex, 1);
            },
            autoUpdateUnit(unit){
                if(!unit.unit_make_id || !unit.unit_model_id || !unit.chassis_number || unit.stacked_units.find(su => (!su.unit_make_id || !su.unit_model_id || !su.chassis_number))) return;
                this.updateUnits(unit);
            },
            updateUnits(units, saveAll = false, afterConfirm = false){
                this.saveDisabled = true;
                let dialogOpen = false;
                if(saveAll){
                    units.job_booking_voyage_job_units.forEach(thisunit => {
                        if(thisunit.id > units.job_booking_voyage_job_units[0].id && !thisunit.customs_reference){
                            thisunit.customs_reference = units.job_booking_voyage_job_units[0].customs_reference;
                        }
                        const measurementsChosen = thisunit.unit_measurement_type_id && thisunit.unit_weight_type_id ? 1 : 0;
                        thisunit.is_checked = thisunit.purchase_measurement_type_id && thisunit.purchase_weight_type_id ? 1 : 0;
                        if(measurementsChosen && !thisunit.are_measurements_chosen && (this.booking.is_operator || thisunit.is_checked) && thisunit.id == saveAll.id){
                            thisunit.are_measurements_chosen = thisunit.unit_measurement_type_id && thisunit.unit_weight_type_id ? 1 : 0;
                            const volumeMeasurements = thisunit.unit_measurement_type_id == 1 ? units.booked_measurement : saveAll.measured_measurement;
                            const weightMeasurements = thisunit.unit_weight_type_id == 1 ? units.booked_measurement : saveAll.measured_measurement;
                            units.volume = this.calcCBM(volumeMeasurements);
                            units.weight = weightMeasurements.weight;
                            units.set_weight_and_volume = true;
                            dialogOpen = true;
                            this.confirmDialog({...thisunit}, {...units});
                            return;
                        }
                    });
                }
                if(dialogOpen){
                    return;
                }
                if(afterConfirm){
                    units.job_booking_voyage_job_units.forEach(thisunit => {
                        if(thisunit.are_measurements_chosen && !thisunit.is_checked){
                            thisunit.unit_measurement_type_id_operator = thisunit.unit_measurement_type_id;
                            thisunit.unit_weight_type_id_operator = thisunit.unit_weight_type_id;
                        }
                    });
                }
                window.setTimeout( () => { this.$nextTick( () => {
                    jobUnitService.update(units).then((response) => {
                        this.saveDisabled = false;
                        if(response.data.files){
                            const fileStart = response.data.files.length - units.file_info.length;
                            const fileEnd = response.data.files.length;
                            for(let i = fileStart; i < fileEnd; i++){
                                units.files.push({ url: units.file_info[i - fileStart].url });
                            }
                        }
                        units.file_info = [];
                        units.delete_images = [];
                        if(units.job_unit_message){
                            units.remarks.splice(0, 0, {
                                id: 0,
                                content: units.job_unit_message,
                                created_at: moment(),
                                user: {
                                    id: 0,
                                    name: store.getters.getName
                                }
                            });
                            this.$parent.$refs.contactHistory.addMessage({
                                user_id: store.getters.getUser.id,
                                content: `${units.make.name} ${units.model.name} ${units.chassis_number}: ${units.job_unit_message}`,
                                created_at: moment(),
                                comment: true,
                                user: {
                                    name: store.getters.getName
                                }
                            });
                            units.job_unit_message = null;
                        }
                        units.make = response.data.make;
                        units.model = response.data.model;
                        response.data.stacked_units.forEach((sunit, sunitIndex) => {
                            units.stacked_units[sunitIndex].make = sunit.make;
                            units.stacked_units[sunitIndex].model = sunit.model;
                        });
                        const overviewRow = document.getElementById('overviewunit-' + this.$parent.job.id + '-' + units.id);
                        if(overviewRow){
                            overviewRow.querySelector('.vinnumber').innerText = units.chassis_number;
                        }
                        response.data.job_booking_voyage_job_units.forEach((jbvju, jbvjuIndex) => {
                            const thisunit = units.job_booking_voyage_job_units.find(ujbvju => ujbvju.id == jbvju.id);
                            if(thisunit) {
                                thisunit.measured_unit_measurement_id = jbvju.measured_unit_measurement_id;
                                /*if(thisunit.status_id != 4 && !thisunit.is_on_hold && thisunit.loaded_date){
                                    thisunit.status_id = 4;
                                }
                                else if(thisunit.status_id == 4 && !thisunit.is_on_hold && !thisunit.loaded_date && thisunit.is_cleared && thisunit.delivered_date){
                                    thisunit.status_id = 3;
                                }
                                else if(thisunit.status_id == 1 && thisunit.is_cleared && !thisunit.is_on_hold && thisunit.delivered_date){
                                    thisunit.status_id = 2;
                                }
                                else if(thisunit.status_id == 2 && thisunit.is_on_hold){
                                    thisunit.status_id = 1;
                                }
                                else if(thisunit.status_id != 1 && (!thisunit.delivered_date || !thisunit.is_cleared)){
                                    thisunit.status_id = 1;
                                }*/
                                thisunit.status_id = jbvju.status_id;
                                units['is_cleared_' + jbvjuIndex] = thisunit.is_cleared;
                                units['is_on_hold_' + jbvjuIndex] = thisunit.is_on_hold;
                                units['is_priority_' + jbvjuIndex] = thisunit.is_priority;
                                units['is_delivered_' + jbvjuIndex] = thisunit.delivered_date ? 1 : 0;
                                units['status_' + jbvjuIndex] = thisunit.status_id;
                                if(thisunit.loaded_date){
                                    const thisBL = this.booking.bills_of_lading.find(bl => bl.id == units.bill_of_lading_id);
                                    if(thisBL && thisBL.bill_of_lading_status_id == 1){
                                        thisBL.bill_of_lading_status_id = 2;
                                    }
                                }
                            }
                        });
                        const booking = this.job.bookings.find(booking => booking.id = response.data.job_booking_id);
                        const billoflading = booking.bills_of_lading.find(bl => bl.id == response.data.bill_of_lading_id);
                        if(billoflading){
                            let blIndex = booking.bills_of_lading.findIndex(bl => bl.id == response.data.bill_of_lading_id);
                            let jobUnit = billoflading.job_units.find(punit => punit.id == response.data.id);
                            if(jobUnit) {
                                let unitIndex = billoflading.job_units.findIndex(punit => punit.id == response.data.id);
                                jobUnit = this.setUnitData(jobUnit, response.data, units.altBooked ? units.booked_measurement : null);
                                this.$parent.resetBLData(jobUnit, blIndex, unitIndex);
                            }
                            delete units.altBooked;
                        }
                        if(billoflading && response.data.job_unit_container_id){
                            const container = billoflading.job_unit_containers.find(punit => punit.id == response.data.job_unit_container_id);
                            let containerUnit = container.job_units.find(punit => punit.id == response.data.id);
                            if(containerUnit) {
                                containerUnit = this.setUnitData(containerUnit, response.data);
                            }
                        }
                        const userName = store.getters.getName;
                        if(!this.$parent.editors.find(editor => editor.name == userName)) this.$parent.addUser(userName);
                        this.$toast.success(this.$t('form.updated_x', [this.$t('navigation.units')]));
                    }).catch(error => {
                        this.saveDisabled = false;
                        this.toastError(error)
                        console.log('error', error)
                    });
                }, 1000)});
            },
            setUnitData(itemdata, data, measurements = null){
                let item = {...itemdata};
                if(item.job_booking_voyage_job_units){
                    data.job_booking_voyage_job_units.forEach(jbvju => {
                        const thisunit = item.job_booking_voyage_job_units.find(ujbvju => ujbvju.id == jbvju.id);
                        if(thisunit) {
                            thisunit.measured_unit_measurement_id = jbvju.measured_unit_measurement_id;
                            thisunit.is_cleared = jbvju.is_cleared;
                            thisunit.is_on_hold = jbvju.is_on_hold;
                            thisunit.status_id = jbvju.status_id;
                            thisunit.loaded_date = jbvju.loaded_date;
                            item.is_surveyed = jbvju.is_surveyed;
                        }
                    });
                }
                else{
                    item.measured_unit_measurement_id = data.job_booking_voyage_job_units[0].measured_unit_measurement_id;
                    item.is_cleared = data.job_booking_voyage_job_units[0].is_cleared;
                    item.is_on_hold = data.job_booking_voyage_job_units[0].is_on_hold;
                }
                item.construction_year = data.construction_year;
                item.commodity_id = data.commodity_id;
                item.condition_id = data.condition_id;
                item.make = data.make;
                item.make_id = data.make_id;
                item.model = data.model;
                item.model_id = data.model_id;
                item.colour_id = data.colour_id;
                item.hs_code = data.hs_code;
                item.license_plate = data.license_plate;
                item.chassis_number = data.chassis_number;
                if(data.volume && data.weight){
                    item.volume = data.volume;
                    item.weight = data.weight;
                }
                else if(measurements){
                    item.weight = measurements.weight;
                    item.volume = (measurements.length * measurements.width * measurements.height) / 1000000;
                }
                const stackedSize = item.stacked_units.length;
                for(let i = 0; i < stackedSize; i++){
                    item.stacked_units[i].make = data.stacked_units[i].make;
                    item.stacked_units[i].make_id = data.stacked_units[i].make_id;
                    item.stacked_units[i].model = data.stacked_units[i].model;
                    item.stacked_units[i].model_id = data.stacked_units[i].model_id;
                    item.stacked_units[i].commodity_id = data.stacked_units[i].commodity_id;
                    item.stacked_units[i].condition_id = data.stacked_units[i].condition_id;
                    item.stacked_units[i].chassis_number = data.stacked_units[i].chassis_number;
                    item.stacked_units[i].construction_year = data.stacked_units[i].construction_year;
                    item.stacked_units[i].hs_code = data.stacked_units[i].hs_code;
                    item.stacked_units[i].colour_id = data.stacked_units[i].colour_id;
                    item.stacked_units[i].license_plate = data.stacked_units[i].license_plate;
                }
                item.description = this.setUnitDescription(item);
                if(this.unitList.length > 0){
                    const ids = this.unitList.map(unit => unit.id);
                    ids.forEach((id, index) => {
                        if(id == item.id){
                            this.unitList[index] = item;
                        }
                    });
                }
                return item;
            },
            setUnitDescription(item){
                if(item.use_generated_unit_description){
                    const oldDescription = ('' + item.description + '');
                    const make = item.make ? item.make.name.toUpperCase() : '';
                    const model = item.model ? item.model.name.toUpperCase() : '';
                    const buildYear = item.construction_year ? item.construction_year : '';
                    const colour = this.colours.find(c => c.id == item.colour_id);
                    const hscode = item.hs_code;
                    let newDescription = make ? (make + ' ' + model) : (item.description ? item.description : '').split("\n")[0];
                    if(item.license_plate) newDescription += ("\nLICENSE PLATE: " + item.license_plate);
                    if(buildYear) newDescription += ("\nBUILD YEAR: " + buildYear);
                    if(colour) newDescription += ("\nCOLOR: " + colour.name);
                    if(hscode) newDescription += ("\nHS CODE: " + hscode);
                    let prevStackedType = '';
                    item.stacked_units.forEach(sunit => {
                        let stackedDescription = '';
                        if(prevStackedType != sunit.stacking_type){
                            stackedDescription = "\nSAID TO BE " + (sunit.stacking_type == 'connected' ? "CONNECTED" : "LOADED") + " WITH:";
                        }
                        stackedDescription += ("\n" + sunit.quantity + "X " + (sunit.condition_id == 1 ? "NEW " : "USED ") + sunit.make.name + ' ' + sunit.model.name + "\nCHASSISNR: " + sunit.chassis_number);
                        prevStackedType = sunit.stacking_type;
                        newDescription += stackedDescription.toUpperCase();
                    });

                    if(newDescription != oldDescription){
                        jobUnitService.updateDescription(item.id, newDescription).catch(error => this.toastError(error));
                        return newDescription;
                    }
                }
                return item.description;
            },
            confirmDialog(response, type){
                if(type){
                    document.getElementById('confirm-dialog').showModal();
                    this.savedItem = response;
                    this.savedType = type;
                }
                else{
                    document.getElementById('confirm-dialog').close();
                    this.savedItem.purchase_measurement_type_id = response ? this.savedItem.unit_measurement_type_id : (3 - this.savedItem.unit_measurement_type_id);
                    this.savedItem.purchase_weight_type_id = response ? this.savedItem.unit_weight_type_id : (3 - this.savedItem.unit_weight_type_id);
                    const responseData = this.savedType;
                    responseData.job_unit_voyage_job_units = [this.savedItem];
                    this.updateUnits(responseData, false, response === true);
                }
            },
            getImages(item, e){
                item.open = !item.open;
                this.$nextTick(() => {
                    const collapsers = document.querySelectorAll('#bookdetails .header-arrow:not(.collapsed)');
                    this.detailsOpen = collapsers.length > 0;
                });
                if(item.imagesLoaded) return;
                fileService.getImages(item.id).then(response => {
                    response.data.forEach((image, imageIndex) => item.files[imageIndex].url = ('"' + image + '"'))
                    item.imagesLoaded = true;
                    this.$forceUpdate();
                }).catch(error => this.toastError(error) );
                const el = document.querySelector(e.target.getAttribute('data-bs-target') + ' li:first-child button');
                if(el) el.click();
            },
            calcCBM(measurements){
                if(!measurements) return '-';
                let cbm = (measurements.length * measurements.width * measurements.height) / 1000000;
                return isNaN(cbm) || cbm == 0 ? '-' : cbm.toFixed(0);
            },
            unlink(sunit){
                if(confirm(`Do you wish to uncouple ${sunit.make.name} ${sunit.model.name}?`)){
                    jobUnitService.unlink(sunit.id).then(response => {
                        window.activeBookingTab = [...[this.$parent.selectedBooking]][0];
                        this.$parent.reset();
                        this.$parent.setData(response.data, false, true);
                    }).catch(error => this.toastError(error));
                }
            },
            downloadImage(image){
                fileService.download(image.id).then(response => {
                    this.triggerDownload(response.data, image.name);
                }).catch(error => this.toastError(error));
            },


            reset() {
                const units = document.querySelectorAll('#detailswrapper .unit-details-acc input[type="checkbox"]');
                units.forEach(field => {
                    if(field.checked)
                        field.click();
                });
                this.detailToOpen = null;
                this.currentLeg = 0;
                this.emailDocumentsModal = false;
                this.email_customer = false;
                this.email_user = false;
                this.email_other = false;
                this.email_otherlist = "";
                this.unitsForPDF = [];
                this.unitList = [];
                this.voyageList = [];
                this.canChangeVoyage = true;
                this.saveDisabled = false;
            },
            downloadAllBLs(){
                return this.downloadPDF('all-bls', [this.job.id], this.job.number + '_bl_summaries');
            },
            downloadBookingSummary(){
                return this.downloadPDF('booking-summary', this.unitsForPDF.join('-'), this.job.number + '_booking_summary');
            },
            downloadBookingRequest(){
                return this.downloadPDF('booking-request', this.unitsForPDF.join('-'), this.job.number + '_booking_request');
            },
            downloadDeliveryInstructions(){
                return this.downloadPDF('delivery-instructions', this.unitsForPDF.join('-'), this.job.number + '_delivery_instructions');
            },
            downloadMasterBL(){
                return this.downloadPDF('master-bl', this.unitsForPDF.join('-'), this.job.number + '_master_bl');
            },
            downloadUnitStickers(){
                return this.downloadPDF('unit-stickers', this.unitsForPDF.join('-'), this.job.number + '_unit_stickers');
            },
            downloadPDF(type, value, name) {
                return jobService.downloadPDF(type, value).then(response => {
                    this.triggerDownload(response.data, name + ".pdf");
                }).catch(error => this.toastError(error));
            },
            downloadExcelList(){
                return jobService.downloadPDF("excel", this.job.id).then(response => {
                    this.triggerDownload(response.data, "job_export_" + this.job.number + ".xlsx");
                }).catch(error => this.toastError(error));
            },
            addPDFUnit(someUnit, remove){
                if(remove){
                    let index = this.unitsForPDF.findIndex(unit => unit == someUnit.id);
                    this.unitsForPDF.splice(index, 1);
                    this.unitList.splice(index, 1);
                }
                else{
                    this.unitsForPDF.push(someUnit.id);
                    this.unitList.push(someUnit);
                }
                let hasPermisUnits = false;
                this.unitList.forEach(unit => {
                    if(unit.status_0 > 2) hasPermisUnits = true;
                });
                this.canChangeVoyage = !this.booking.measurements_fixed || !hasPermisUnits;
            },
            addPDFUnits(remove){
                const units = document.querySelectorAll('#detailswrapper .unit-detail-checkbox input');
                units.forEach(field => {
                    if(!field.checked ^ remove)
                        field.click();
                });
            },
            bulkActionUnits(field){
                let newValue = 0;
                let unitsToUpdate = [];
                this.unitList.forEach(someunit => {
                    someunit.job_booking_voyage_job_units.forEach(unit => {
                        if(!unit[field])
                            newValue = 1;
                    });
                    someunit.job_booking_voyage_job_units.forEach(unit => {
                        unit[field] = newValue;
                        unitsToUpdate.push(unit.id);
                        if(unit.status_id == 1 && unit.is_cleared && !unit.is_on_hold && unit.delivered_date)
                            unit.status_id = 2, someunit.status_id = 2;
                        if(unit.status_id != 1 && (unit.is_on_hold || !unit.is_cleared))
                            unit.status_id = 1, someunit.status_id = 1;
                    });
                    someunit[field] = newValue;
                })

                voyagePlanningService.update({'field': field, 'new_value': newValue, 'voyage_id': null, 'units': unitsToUpdate}).then(response => {
                    const userName = store.getters.getName;
                    const userId = store.getters.userId;
                    if(!this.$parent.editors.find(editor => editor.name == userName) && userName) {
                        this.$parent.addUser(userName);
                        jobService.addUsers([userId], this.$parent.job.id);
                    }
                    this.$toast.success(this.$t('form.updated_x', [this.$tc('navigation.units', 2)]));
                }).catch(error => this.toastError(error));
            },
            cancelUnits(){
                if(this.job.user_customer.id && this.unitList.find(unit => unit.status_0 > 2)){
                    alert("You cannot cancel units that are in permit or are loaded.");
                    return;
                }
                if(confirm(this.$t('operations.cancel_units_message'))){
                    voyagePlanningService.update({'field': 'is_cancelled', 'new_value': true, 'voyage_id': null, 'units': this.unitsForPDF, 'job_id': this.job.id}).then(response => {
                        window.activeBookingTab = [...[this.$parent.selectedBooking]][0];
                        this.$parent.setData(response.data);
                        this.$parent.$parent.getIndex();
                        this.$parent.resetMPStatus = true;
                        document.querySelectorAll('#detailswrapper .unit-details-acc input[type="checkbox"]').forEach(el => el.checked = false);
                        this.reset();
                    }).catch(error => this.toastError(error));
                }
            },
            uncancelUnit(unit){
                if(confirm(this.$t('operations.cancel_units_message_alt'))){
                    voyagePlanningService.update({'field': 'is_cancelled', 'new_value': false, 'voyage_id': null, 'units': [unit.id], 'job_id': this.job.id}).then(response => {
                        window.activeBookingTab = [...[this.$parent.selectedBooking]][0];
                        this.$parent.setData(response.data);
                        this.$parent.$parent.getIndex();
                        this.$parent.resetMPStatus = true;
                        this.reset();
                    }).catch(error => this.toastError(error));
                }
            },
            changeVoyageOpen(){
                this.$parent.changeVoyageIsOpen = true;
                this.$parent.$refs.changeVoyagePopup.resetWithJob(this.$parent.job, this.$parent.$refs.bookingCreate.filteredVoyages, this.voyageList, this.unitList, this.booking);
            },
            changeVoyage(value, voyageInfo, extraRemarks){
                let newValue = [{'voyage': value, 'job': this.$parent.job.id, 'remarks': extraRemarks,  'units': [], 'units_voyage': [], 'booking_ids': [], 'split_booking': false}];
                this.unitList.forEach(someunit => {
                    newValue[0].units.push(someunit.id);
                    someunit.job_booking_voyage_job_units.forEach(unit => newValue[0].units_voyage.push(unit.id));
                });
                const allUnits = document.querySelectorAll('#detailswrapper .unit-detail-checkbox input[type="checkbox"]');
                let voyageId = null;
                let checkedUnits = [];
                let uncheckedUnits = [];
                allUnits.forEach(el => {
                    const bookingid = Number(el.id.split('-')[3]);
                    if(el.checked){
                        if(bookingid && !newValue[0].booking_ids.includes(bookingid)){
                            newValue[0].booking_ids.push(bookingid);
                            checkedUnits.push(bookingid);
                        }
                    }
                    else if(bookingid){
                        uncheckedUnits.push(bookingid);
                    }
                });
                checkedUnits.forEach(unit => {
                    if(uncheckedUnits.includes(unit))
                        newValue[0].split_booking = true;
                });
                if(!newValue[0].split_booking){
                    newValue[0].split_booking = newValue[0].booking_ids.length != 1;
                }
                if(!newValue[0].split_booking){
                    voyageId = this.job.bookings.find(b => b.id == newValue[0].booking_ids[0]).voyages[0].id;
                }

                voyagePlanningService.update({'field': 'voyage', 'new_value': newValue, 'voyage_id': voyageId, 'units': [], 'dates': {'pol': voyageInfo.pol_date, 'pod': voyageInfo.pod_date}}).then(response => {
                    jobService.show(this.$parent.job.id).then(response => {
                        window.activeBookingTab = [...[this.$parent.selectedBooking]][0];
                        this.$parent.setData(response.data);
                        this.$parent.$parent.getIndex();
                        this.$parent.resetMPStatus = true;
                        document.querySelectorAll('#detailswrapper .unit-details-acc input[type="checkbox"]').forEach(el => el.checked = false);
                        this.reset();
                    }).catch(error => this.toastError(error));
                }).catch(error => console.log('error', error));
            },
            closeInnerModel(e){
                if(e.target.classList.contains('d-block')){
                    this.emailDocumentsModal = false;
                }
            },
            emailDocuments(e) {
                jobService.emailDocuments(this.jobItem.id, this.unitsForPDF, this.email_customer, this.email_user, this.email_otherlist).then(response => {
                    this.emailDocumentsModal = false;
                    if(response.data.emails_failed == 0)
                        this.$toast.success(this.$tc('operations.' + response.data.message, response.data.emails_sent));
                    else
                        this.$toast.error(this.$tc('operations.' + response.data.message, response.data.emails_failed));
                }).catch(error => this.toastError(error));
            },
            showTooltip(e){
                var tooltip = e.target.nextElementSibling;
                const scrollingWindow = document.getElementById('jobTabContent');
                tooltip.classList.add('show');
                var x = 0;
                var ele = e.target;
                while(ele.offsetParent !== null && ele.id != 'main-table'){
                    x += ele.offsetLeft;
                    ele = ele.offsetParent;
                }
                tooltip.style.left = (x + (10 - (tooltip.scrollWidth / 2))) + 'px';
                tooltip.style.transform = 'translateY(-' + (tooltip.scrollHeight + ele.scrollTop + scrollingWindow.scrollTop + 20) + 'px)';
            },
            hideTooltip(e){
                e.target.nextElementSibling.classList.remove('show');
            },
            openCollapser(e){
                let el = e.target;
                if(el.tagName == 'INPUT' || e.tagName == "I") return;
                while(el.role != 'clickable') el = el.parentElement;
                el.nextElementSibling.click();
            }
        },
        mounted(){
            this.customsRefOptions = dataStore.getters.getCustomTypes;
            this.currentOffice = store.getters.getCompanyId;
            this.statuses = ['', this.$t('overview.vp_waiting'), this.$t('overview.vp_firm'), this.$t('overview.vp_permis'), this.$t('operations.date_loaded')]
        }
    }
</script>

<style scoped>
    .voyage-block > .vport, .voyage-block > .vlabel{
        flex: 0 1 0;
        white-space: nowrap;
    }
    .vlabel > div{
        padding-top: .5rem;
        font-size: 1.2rem;
        line-height: 1rem
    }
    .vlabel > span{
        font-size: .85rem;
        color: gray;
        text-transform: uppercase;
    }
    .voyage-block > .vport{
        align-self: end;
    }
    .voyage-block > .vconn{
        position: relative;
        top: 18px;
        flex: 1 1 0;
    }
    .voyage-block > .vport > *, .voyage-block > .vconn > *{
        display: block;
    }
    .voyage-block > .vport > i{
        width: 24px;
        height: 24px;
        border: 2px solid var(--bs-primary);
        border-radius: 100px
    }
    .voyage-block > label:first-child{
        text-align: left;
    }
    .voyage-block > label:not(:first-child):not(:last-child){
        text-align: center;
    }
    .voyage-block > label:last-child{
        text-align: right;
    }
    .voyage-block > .inactive{
        opacity: 50%
    }
    .voyage-block > .vconn > div{
        width: 100%;
    }
    .voyage-block > .vconn > div > i{
        display: inline-block;
        position: relative;
        bottom: 24px;
        height: 1px;
    }
    .voyage-block > .vconn > div > i:first-child{
        border: 1px solid var(--bs-primary);
    }
    .voyage-block > .vconn > div > b{
        color: var(--bs-primary);
        position: relative;
        bottom: 20px;
        right: 1px
    }
    .voyage-block > .vconn > div > i:last-child{
        border: 1px dashed var(--bs-primary);
    }
    .voyage-block > .vconn > span{
        position: relative;
        text-align: center;
        bottom: 16px
    }
    .voyage-block > img{
        border-radius: 100px
    }
    .acc-header-item.header-arrow {
        background: transparent;
        border: none;
        align-self: baseline
    }
    .acc-header-item.header-arrow::after {
        transition: rotate .15s
    }
    .acc-header-item.header-arrow::after {
        display: block;
        content: '\276F'
    }
    .acc-header-item:not(.collapsed).header-arrow::after {
        rotate: 90deg
    }
    .accordion{
        width: calc(100% - 1rem)
    }
    .accordion:not(:last-child){
        border-bottom: 1px solid #ddd;
        padding-bottom: 4px
    }
    .accordion:not(:first-child){
        padding-top: 4px
    }
    .tab-content{
        border: 1px solid var(--bs-primary);
        border-radius: 0 3px 3px 3px;
    }
    .nav-item > button{
        border-radius: 3px 3px 0 0;
        box-shadow: none !important;
        position: relative;
        top: 1px
    }
    .nav-item > button.active{
        border: 1px solid var(--bs-primary);
        border-bottom-color: white !important;
        color: var(--bs-primary);
        background-color: white;
    }
    .unit-details-acc .col-2x{
        width: 18% !important
    }
    .unit-details-acc .col-3x{
        width: 21% !important
    }
    .col-auto.unit-details-acc:hover{
        background-color: #f8f9fa;
        cursor: pointer;
    }
    .image-drop, .uploaded-image{
        width: 120px;
        height: 80px;
    }
    .image-drop{
        border: 3px dashed #888;
        color: #888;
        display: flex;
        text-align: center;
        align-items: center
    }
    .image-drop:hover{
        border-color: #0d6efd;
        color: #0d6efd;
        cursor: pointer
    }
    .uploaded-image{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-left: 0.5rem;
        border: 1px solid black;
        text-align: end
    }
    .uploaded-image > i{
        background-color: rgba(250, 250, 250, 0.5);
        width: 20px;
        padding: 4px;
    }
    .uploaded-image > .fa-close:hover{
        color: red;
        cursor: pointer
    }
    .uploaded-image > .fa-download:hover{
        color: #0e7;
        cursor: pointer
    }
    .disabled{
        background-color: #e9ecef
    }
    pre{
        font-family: var(--bs-body-font-family);
        line-height: 1.5rem;
        padding-left: 4px
    }
    .border-left{
        border-left: 4px solid gray;
        padding-left: 1%;
    }
    .form-check-input{
        border-radius: 4px !important
    }
    .fa-xs.fa-link{
        rotate: 80deg
    }
    .fa-hybrid:hover > .fa-link::before{
        color: red;
        content: "\f127" !important;
        cursor: pointer;
    }
    .fa-hybrid:hover > .fa-turn-down-right::before{
        color: red;
        content: "\e3d6" !important;
        cursor: pointer;
    }
    .col-sm-1p5{
        width: 12% !important;
        flex: 0 0 auto
    }
    .col-sm-1p4{
        width: 10.4% !important;
        flex: 0 0 auto
    }



    .btn-pdf:not([disabled]){
        background-color: #0d6efd;
        border-color: #0d6efd;
        color: #fff
    }
    .btn-prio:not([disabled]){
        background-color: #F4511E;
        border-color: #F4511E;
        color: #fff
    }
    .btn-clea:not([disabled]){
        background-color: #43A047;
        border-color: #43A047;
        color: #fff
    }
    .btn-hold:not([disabled]){
        background-color: #0dcaf0;
        border-color: #0dcaf0;
        color: #fff
    }
    .btn-pdf[disabled], .btn-status[disabled]{
        background-color: #999;
        border-color: #999;
        color: #fff
    }
    .btn-sm:hover{
        filter: brightness(94%)
    }
    .disabled{
        background-color: #e9ecef
    }
    .cancelled .col-auto{
        text-decoration: line-through
    }
    .text-link{
        color: #00f;
        text-decoration: underline;
    }
    .text-link:hover{
        color: #66f;
        cursor: pointer;
    }
    .unit-label{
        color: white;
        border-radius: 3px;
        display: inline-block;
        width: 16px;
        text-align: center;
        font-size: 0.75rem;
        margin-right: 2px;
    }
    sub{
        bottom: unset;
    }
    .remark-blurb{
        display: none;
        position: fixed;
        text-align: center;
        z-index: 1111;
        background-color: #333;
        color: white;
        border-radius: 3px;
        padding: 4px;
        max-width: 250px;
        font-size: 0.8rem;
        font-family: unset
    }
    .remark-blurb.show{
        display: block !important;
    }
    #confirm-dialog{
        background-color: #fff;
        border: 1px solid rgba(0,0,0,.2);
        border-radius: .3rem;
        outline: 0
    }
    .bg-gwarning{
        background-color: #F90 !important;
    }
</style>
<style>
#detailswrapper .green-measured.checked > .col-dimensions .measure-field, #detailswrapper .green-weight.checked > .col-weight .measure-field,
#detailswrapper .green-measured.checked > .col-dimensions .measure-field + span, #detailswrapper .green-weight.checked > .col-weight .measure-field + span {
    background-color: #d5e9c0 !important
}
</style>